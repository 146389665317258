import { H6, Button, H5 } from '../../shared/components'
import styled from 'styled-components'
import { color } from '../../shared/utils/styles'
import { ReactComponent as PlusArrow } from '../../assets/icongraphic.svg'
import { ReactComponent as RemoveIcon } from '../../assets/icons_remove.svg'
import { ReactComponent as AddIcon } from '../../assets/icons_add_new.svg'
const MessageContainer = styled.div`
  margin-top: 50px;
`

export const StyledLine = styled.hr`
  border: 0.5px solid ${color.gray70};
  margin-bottom: 0px;
  opacity: 0.4;
  width: 100%;
`

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  font-size: 16px;
  color: white;
  align-items: center;
  justify-content: flex-start;
`

export const PartnerH5 = styled(H5)`
  margin-left: 8px;
  font-size: 28px;
  margin-bottom: 18px;
  margin-right: auto;
`

export { MessageContainer }

export const TilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 28px;
  margin-left: 10%;
`
export const TextTile = styled.div`
  width: 180px;
  height: 145px;
  padding: 1em 0px;
  margin-right: 3em;
  margin-bottom: 1.5em;
  margin-top: 1.5em;
  background-color: ${color.white};
  text-align: center;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
`

export const NumberText = styled.div`
  font-size: 40px;
`
export const TileImage = styled.img`
  width: 8em;
  height: 5em;
`

export const DescriptionText = styled(H6)`
  text-align: center;
  padding-top: 0.3em;
  left: 0;
  right: 0;
  bottom: 5px;
  color: black;
`

export const DescOnHover = styled.div`
  width: 180px;
  height: 135px;
  background-color: ${color.white};
  text-align: center;
  position: absolute;
  z-index: 9;
  top: 0px;
  left: -15px
  font-size: 12px;
  height: 100%;
  padding: 1em 0px;
  justify-content: center;
  box-sizing: border-box;
`

export const Para = styled.p`
  font-size: 11px;
  color: ${color.gray95};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  height: 64%;
  padding: 0px 4px;
  width: 91%;
`
export const CreateRow = styled.div`
  max-width: inherit;
  display: flex;
  justify-content: space-evenly; ;
`

export const CreateButton = styled(Button)`
  font-size: 1em;
  height: 40px;
  margin-top: 20px;
  font-weight: 100;
  margin-left: auto;
  max-width: 250px;
`
export const PlusArrowPartner = styled(PlusArrow)`
  margin-top: 5px;
  height: 12px;
`
export const RemoveIconPartner = styled(RemoveIcon)`
  height: 16px;
  position: absolute;
  right: 1px;
  top: 3px;
  cursor: pointer;
`

export const AddIconPartner = styled(AddIcon)`
  width: 80px;
  height: 68%;
`

export const KnowMoreButton = styled(Button)`
  background-color: ${color.white};
  border-radius: 3px;
  border: 1px solid ${color.blueBase};
  color: ${color.blueBase};
  font-size: 11px;
  margin-botton: 5px;
`

export const NoRecordContainter = styled.div`
  height: 70vh;
  color: white;
`
