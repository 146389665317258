import React from 'react'
import ReactECharts from 'echarts-for-react'

interface PieChartProps {
  data: any
  objmap: any
}

const PieChart: React.FC<PieChartProps> = ({ data, objmap }) => {
  if (!data) {
    return <div>Loading...</div>
  }

  const seriesData = Object.keys(data).map((primaryFilter) => ({
    name: objmap[primaryFilter] || primaryFilter,
    value: data[primaryFilter],
  }))

  const option = {
    color: ['#e3e5e6', '#6e99b4', '#0071b9', '#338dc7', '#aaafb3'],
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)',
    },
    legend: {
      textStyle: {
        color: '#e3e5e6',
      },
    },
    series: [
      {
        name: 'RingChart',
        type: 'pie',
        radius: ['50%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: true,
          position: 'outside',
          formatter: '{b}: {c}',
          color: '#e3e5e6',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '20',
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: true,
        },
        data: seriesData,
      },
    ],
  }

  return <ReactECharts option={option} style={{ height: '500px' }} />
}

export default PieChart
