import Button from '../../../shared/components/Button'
import styled, { css } from 'styled-components'
import { color } from '../../../shared/utils/styles'
import { Checkbox } from 'antd'
import { ReactComponent as Filter } from '../../../assets/funnel.svg'

export const Export = styled(Button)`
  width: 110px;
  // align-self: flex-end;
  margin-left: 300px;
  margin-top: 20px;
  color: #fff;
  background: #208cc7;
  border-color: #208cc7;
`

const rowCommonStyle = css`
  color: ${color.gray10};
  max-width: 1388px;
  width: 100%;
  padding: 8px 8px 8px 16px;
  display: flex;
  align-items: center;
`

export const HeaderRow = styled.div`
  ${rowCommonStyle};
  background: ${color.gray80} 0% 0% no-repeat padding-box;
  font-size: 14px;
  border-top: 1px solid ${color.gray70};
  height: 60px;
`
export const Row = styled.div`
  ${rowCommonStyle};
  background-color: ${color.gray85};
  min-height: 48px;
  margin-bottom: 2px;
  border-radius: 4px;
`

export const RowContent = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const OrgRowContent = styled(RowContent)`
  flex-basis: 33%;
`
export const OrgRowContentLarge = styled(RowContent)`
  flex-basis: 70%;
`
export const OrgRowContentMedium = styled(RowContent)`
  flex-basis: 50%;
`

export const FilterIcon = styled(Filter)`
  margin-top: 5px;
  height: 45%;
  opacity: 0.5;
  margin-right: 5px;
`
export const OrgRowCountry = styled.div`
  display: flex;
  flex-direction: column;
  position: relative; /* Added to allow absolute positioning of child elements */
`
export const FilterButton = styled.div`
  display: flex;
  align-items: center;
  width: 135px;
  height: 30px;
  color: #fff;
  background: #262e33;
  border: #262e33;
  cursor: pointer;
  border-radius: 3px;
  transition: background 0.3s, color 0.3s;

  &:hover {
    background: #208cc7; /* Optional: change background on hover */
  }

  &:active {
    background: #208cc7;
    color: #fff;
    border-color: #208cc7;
  }
`
export const FilterCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    border-color: #208cc7;
  }

  &:first-child {
    margin-left: 8px;
  }
`
export const StyleCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #3d464c;
  border-radius: 3%;
  position: absolute;
  top: 100%; /* Positioned right below the FilterButton */
  left: 0;
  z-index: 1; /* Ensure it's above other elements */
`
