import React, { useState } from 'react'
import { H2, H4 } from '../../../shared/components'
import { LeftCol, SectionRow } from '../Styles'
import { ReleaseNotesLog } from './sections/ReleaseNotesLogDecember22'
import { ReleaseNotesLogJune } from './sections/ReleaseNotesLogJune23'
import { ReleaseNotesLogJuly } from './sections/ReleaseNotesLogJuly23'
import { ReleaseNotesLogJuly2 } from './sections/ReleaseNotesLogJuly23.2.0'
import { Row, RowExpand, IconExpand, IconHide } from './Styles'
import { ReleaseNotesLogJuly3 } from './sections/ReleaseNotesLogJuly23.3.0'
import { ReleaseNotesLogAugust4 } from './sections/ReleaseNotesLogAugust23'
import { ReleaseNotesLogSeptember23 } from './sections/ReleaseNotesLogSeptember23'
import { ReleaseNotesLogOctober23 } from './sections/ReleaseNotesLogOctober23'
import { ReleaseNotesLogOctober2 } from './sections/ReleaseNotesLogOctober23.2.0'
import { ReleaseNotesLogNovemeber23 } from './sections/ReleaseNotesLogNovemeber23'
import { ReleaseNotesLogDecember2 } from './sections/ReleaseNotesLogDecember23.2.0'
import { ReleaseNotesLogDecember23 } from './sections/ReleaseNotesLogDecember23'
import { ReleaseNotesLogDecember3 } from './sections/ReleaseNotesLogDecember23.3.0'
import { ReleaseNotesLogJanuary } from './sections/ReleaseNotesLogJanuary'
import { ReleaseNotesLogJanuary2 } from './sections/ReleaseNotesLogJanuary2'
import { ReleaseNotesLogFebruary } from './sections/ReleaseNotesLogFebruary'
import { ReleaseNotesLogFebruary2 } from './sections/ReleaseNotesLogFebruary24.2.0'
import { ReleaseNotesLogMarch } from './sections/ReleaseNotesLogMarch24'
import { ReleaseNotesLogMarch2 } from './sections/ReleaseNotesLogMarch24.2'
import { ReleaseNotesLogApril } from './sections/ReleaseNotesLogApril24'
import { ReleaseNotesLogApril2 } from './sections/ReleaseNotesLogApril24.2'
import { ReleaseNotesLogMay24 } from './sections/ReleaseNotesLogMay24'
import { ReleaseNotesLogMay2 } from './sections/ReleaseNotesLogMay24.2'
import { ReleaseNotesLogMay3 } from './sections/ReleaseNoteLogMay24.3'
import { ReleaseNotesLogJune24 } from './sections/ReleaseNoteLogJune24'
import { ReleaseNotesLogJuly24 } from './sections/ReleaseNoteLogJuly24'
import { ReleaseNotesLogAugust24 } from './sections/ReleaseNotesLogAugust24'

export const ReleaseNotesDocumentation = () => {
  const [isIconExpand, setIsIconExpand] = useState(true)
  return (
    <SectionRow>
      <LeftCol>
        <H2 style={{ marginLeft: '-30px', marginTop: '-40px' }}>Release Notes: API Developer Portal</H2>
        <>
          <ReleaseNotesLogAugust24 />
          <ReleaseNotesLogJuly24 />
          <ReleaseNotesLogJune24 />
          <ReleaseNotesLogMay3 />
          <ReleaseNotesLogMay2 />
          <ReleaseNotesLogMay24 />
          <ReleaseNotesLogApril2 />
          <ReleaseNotesLogApril />
          <ReleaseNotesLogMarch2 />
          <ReleaseNotesLogMarch />
          <ReleaseNotesLogFebruary2 />
          <Row>
            <H4 id="older-release-notes" style={{ marginLeft: '-30px' }}>
              Previous Releases
            </H4>
            <RowExpand onClick={() => setIsIconExpand(!isIconExpand)}>
              {isIconExpand ? (
                <>
                  <IconExpand />
                </>
              ) : (
                <>
                  <IconHide />
                </>
              )}
            </RowExpand>{' '}
          </Row>

          {!isIconExpand && (
            <>
              <ReleaseNotesLogFebruary />
              <ReleaseNotesLogJanuary2 />
              <ReleaseNotesLogJanuary />
              <ReleaseNotesLogDecember3 />
              <ReleaseNotesLogDecember2 />
              <ReleaseNotesLogDecember23 />
              <ReleaseNotesLogNovemeber23 />
              <ReleaseNotesLogOctober2 />
              <ReleaseNotesLogOctober23 />
              <ReleaseNotesLogSeptember23 />
              <ReleaseNotesLogAugust4 />
              <ReleaseNotesLogJuly3 />
              <ReleaseNotesLogJuly2 />
              <ReleaseNotesLogJuly />
              <ReleaseNotesLogJune />
              <ReleaseNotesLog />{' '}
            </>
          )}
        </>
      </LeftCol>
    </SectionRow>
  )
}
