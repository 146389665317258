import React, { FC } from 'react'
import { StyledLine, Stats } from '../Styles'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../Paths'
import StatHeader from '../StatHeader'
import awsConfig from '../../../shared/utils/awsConfig'
import { statDashboardEnvs } from '../../../shared/constants/common'

const StatDashboard: FC = () => {
  const showStatistics = () => {
    return (
      <>
        <>
          <Stats>
            <Link style={{ color: 'white', marginRight: '20px' }} to={Paths.Statistics.path}>
              Statistics
            </Link>
            {!statDashboardEnvs.includes(awsConfig?.environment) && (
              <Link style={{ color: 'white' }} to={Paths.StatDashboard.path}>
                Dashboard
              </Link>
            )}
          </Stats>
          <StatHeader />
          <StyledLine />
        </>
      </>
    )
  }

  return <>{showStatistics()}</>
}

export default StatDashboard
