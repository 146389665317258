import React from 'react'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../../Paths'

export const ReleaseNotesLogAugust24 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        Latest Release - August 2024
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        August release notes summarizes latest changes for Equipment Status API and bug fixes.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>Equipment Status API</li>
          <UL>
            <li>
              The field <q>devices</q> is now available in{' '}
              <Link to={Paths.EquipmentStatus2WebhookEvents.path + '#availability-event'}>
                {' '}
                Equipment Availability Information Webhook API
              </Link>
              .
            </li>
            <li>
              Portal documentation has been updated with <q>deviceType</q> coming from{' '}
              <Link to={Paths.EquipmentStatus2RestAPIDocs.path + '#getEquipmentAvailabilityByIds'}>
                {' '}
                Equipment Availability REST API
              </Link>{' '}
              response.{' '}
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>Service Info API</li>
          <UL>
            <li>The bug related to sending duplicate events in the Service Info Webhook API has been fixed.</li>
          </UL>
        </OL>
      </P>
    </>
  )
}
