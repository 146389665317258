import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { DatePicker, Select } from 'antd'
import { useQueryClient } from 'react-query'
import moment from 'moment'
import { ResourcesAPI } from '../../../../../shared/api/resources'
import { Toast } from '../../../../../shared/components'
import { Resource, Building, Contract } from '../../../../../shared/models/resource'
import { EditResourcesStrings } from '../../../../../shared/strings/OrganizationsContent'
import ProductOptions from '../ProductOptions'
import GroupResourceList from '../GroupResourceList'
import { ReactComponent as OkIcon } from '../../../../../assets/ok-check-icon.svg'
import { ReactComponent as AddIcon } from '../../../../../assets/plus-icon.svg'
import RingSpinner from '../../../../../shared/components/RingSpinner'

import {
  Loader,
  AddContainer,
  MessageContainer,
  SwitchContainer,
  TableRow,
  Row,
  DatePickerContainer,
  RowExpand,
  IconExpand,
  IconHide,
  HeaderRow,
  RowContentSmall,
  RowContentLarge,
  RowContentMedium,
  ProductSelectionAuto,
  RowContentExtraSmall,
} from './Styles'
import _ from 'lodash'
import { UpdateCounterBuilding } from '../Styles'
import { removeV1 } from '../../../../../shared/utils/common'
import {
  validateAddressLine1,
  validateAddressLine2,
  validateCity,
  validateCountryCode,
  validateZipCode,
} from '../../../../../shared/utils/validateAddress'

const { Option } = Select

type Props = {
  organizationId: number
  building: Building
  isLatest: boolean
  contract?: Contract
}

const BuildingResourceList: FC<Props> = ({ organizationId, building, isLatest, contract }) => {
  const [date, setDate] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [apiErrors, setAPIErrors] = useState<string[]>()
  const [isSuccessVisible, setIsSuccessVisible] = useState(false)
  const [isErrorVisible, setIsErrorVisible] = useState(false)
  const [isInputErrorVisible, setIsInputErrorVisible] = useState(false)
  const [isExpires, setIsExpiry] = useState(true)
  const queryClient = useQueryClient()
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const [productsSelected, setProductsSelected] = useState<Array<string>>([])
  const [resourceId, setResourceId] = useState<string>('')
  const [isSaving, setIsSaving] = useState(false)
  const [isOk, setIsOk] = useState(false)
  const [isIconExpand, setIsIconExpand] = useState(true)
  const [contractError, setContractError] = useState<string>('')
  const [isContractError, setIsContractError] = useState(false)
  const [isAddWithoutExpiryError, setAddWithoutExpiryError] = useState(false)
  const [contractDatForSelectedProducts, setContractDatForSelectedProducts] = useState<any>({})
  const [expiredDateManual, setExpiredDateManual] = useState<string>('')
  const [noContractFound, setNoContractFound] = useState(false)
  const [productContractMap, setProductContractMap] = useState({})
  const [currentProduct, setCurrentProduct] = useState<string>('')
  const [ownProductDateMap, setOwnProductDateMap] = useState<any>({})
  const onExpirySwitch = (checked: boolean) => {
    //setExpiryDate(checked)
    setDate('')
  }

  useEffect(() => {
    const temp = {}
    contract?.contractData?.map((oneContract: any) => {
      temp[oneContract?.product] = oneContract?.endDate
    })

    setOwnProductDateMap(temp)
  }, [])

  const init = () => {
    const temp = { ...contractDatForSelectedProducts }
    for (const key in ownProductDateMap) {
      const keyP = removeV1(key)
      console.log('Now Change Date')
      if (!temp[keyP]) {
        temp[keyP] = {}
      }
      temp[keyP] = {
        contractType: null,
        endDate: moment(ownProductDateMap[keyP]).format(EditResourcesStrings.dateFormat),
        product: keyP,
      }
      console.log(
        'contractDatForSelectedProducts111: ',
        temp,
        date,
        moment(date).format(EditResourcesStrings.dateFormat)
      )
    }
    setContractDatForSelectedProducts(temp)
  }
  const resetFlags = () => {
    setIsSuccessVisible(false)
    setIsInputErrorVisible(false)
    setIsLoading(false)
    setAPIErrors([])
    setIsErrorVisible(false)
    setIsOk(false)
  }

  const updateProductExpiryDate = (date: any) => {
    console.log('updateProductExpiryDate:', date, ownProductDateMap)
    if (date && currentProduct?.length > 0) {
      if (date == '' && !ownProductDateMap[currentProduct]) {
        return
      }

      if (date == '' && ownProductDateMap[currentProduct]) {
        date = ownProductDateMap[currentProduct]
      }

      const temp = { ...contractDatForSelectedProducts }
      for (const productKey in productContractMap) {
        if (productContractMap[productKey] === 1) {
          console.log('Now Change Date', productKey, productContractMap, contractDatForSelectedProducts)
          if (!temp[productKey]) {
            temp[productKey] = {
              contractType: null,
              endDate: moment(date).format(EditResourcesStrings.dateFormat),
              block: 0,
              product: productKey,
            }
          }
        }
      }
      setContractDatForSelectedProducts(temp)
    }
  }
  const onDateChange = (date: any, dateString: any) => {
    updateProductExpiryDate(date)
    if (noContractFound) {
      setDate(dateString)
      setIsDatePickerOpen(true)
      setExpiredDateManual(dateString)
      return
    }
    setDate(dateString)
    setIsDatePickerOpen(false)
    resetFlags()
  }

  const validate = (value: string) => {
    if (!value) {
      return EditResourcesStrings.resourceCannotBeEmpty
    }
    return
  }

  const checkIfResourceExists = async (resourceType: string, resourceId: string) => {
    try {
      await ResourcesAPI.getResourcesByTypeAndId(resourceType, resourceId)
      return true
    } catch (e) {
      if (e.response.status === 404) {
        return false
      } else {
        throw new Error(EditResourcesStrings.getResourceError)
      }
    }
  }
  function disabledDate(current: any) {
    return current && current <= moment().endOf('day')
  }

  const addResource = async (building: Building) => {
    const expiryDateForProducts: any[] = []
    productsSelected.forEach((product) => {
      expiryDateForProducts.push(contractDatForSelectedProducts[product].endDate)
    })

    // if (noContractFound) {
    //   for (let i = 0; i < productsSelected.length; i++) {
    //     expiryDateForProducts.push(expiredDateManual)
    //   }
    // } else {
    // }
    let address = {}
    if (building.address) {
      address = fetchAddressFields(building.address)
    }

    const body: Resource = {
      products: productsSelected,
      expiredDate: expiryDateForProducts,
      resourceId: { type: 'building', uniqueId: building.id },
    }
    const resourceExists = await checkIfResourceExists('building', building.id)
    if (!resourceExists) {
      await ResourcesAPI.createResource({
        id: { type: 'building', uniqueId: building.id, expiredDate: date },
        info: { address: JSON.stringify(address) },
      })
    }
    await ResourcesAPI.link(organizationId, body)
  }

  const onProductsChange = (value: any) => {
    setProductsSelected(value)
  }

  const fetchAddressFields = (locationDetails: any) => {
    const addressLine1 = validateAddressLine1(locationDetails)
    const addressLine2 = validateAddressLine2(locationDetails)
    const countryCode = validateCountryCode(locationDetails)
    const city = validateCity(locationDetails)
    const zipCode = validateZipCode(locationDetails)

    const response = {
      city: city,
      country: countryCode,
      zipcode: zipCode,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
    }
    return response
  }

  const onClickAdd = async () => {
    resetFlags()
    if (productsSelected.length < 1) {
      setIsInputErrorVisible(true)
      return
    }

    if (!noContractFound) {
      if (Object.keys(contractDatForSelectedProducts).length !== productsSelected.length) {
        setIsContractError(false)
        setAddWithoutExpiryError(true)
        return
      }
    }

    if ((noContractFound && expiredDateManual.length === 0) || (noContractFound && isExpires === false)) {
      setContractError('Cannot add without expiry date')
      return
    }

    setIsSaving(true)
    const errors = []
    try {
      await addResource(building)
      setAddWithoutExpiryError(false)
      setIsContractError(false)
    } catch (error) {
      const errorMessage = error?.response?.data?.message ?? EditResourcesStrings.errorOccurred
      errors.push(`${resourceId}: ${errorMessage}`)
    }

    if (errors.length) {
      setAPIErrors(errors)
      setIsErrorVisible(true)
    } else {
      setIsSuccessVisible(true)
    }
    setIsOk(true)
    setIsSaving(false)
    setResourceId('')
    queryClient.invalidateQueries(`${ResourcesAPI.queryNames.GET_BY_ORGANIZATION_ID}${organizationId}`)
  }
  {
    apiErrors && apiErrors.map((apiError) => <MessageContainer key={apiError}>{apiError}</MessageContainer>)
  }

  async function setProductExpiryDate(products: string[]) {
    if (contract) {
      if (products.length === 0) {
        setIsExpiry(true)
        onDateChange('', '')
        setIsContractError(false)
        setAddWithoutExpiryError(false)
        return
      }
      const temp = { ...contractDatForSelectedProducts }
      const tempMap = {}
      for (let productIndex = 0; productIndex < products.length; productIndex++) {
        console.log('inside')
        let myResourceContract = _.findLast(
          contract.contractData,
          (contractItem) => contractItem.product == products[productIndex]
        )

        if (!myResourceContract) {
          const newProduct = removeV1(products[productIndex])
          myResourceContract = temp[newProduct]
          tempMap[products[productIndex]] = 1
        }

        if (Object.keys(contractDatForSelectedProducts).length > 0) {
          for (const currentProduct in temp) {
            const newProduct = removeV1(products[productIndex])
            if (!products.includes(newProduct)) {
              delete temp[currentProduct]
            }
          }
        }
        if (myResourceContract) {
          setNoContractFound(false)
          temp[myResourceContract.product] = myResourceContract
          setIsExpiry(false)
          const sortedContracts = contract?.contractData

            .filter((contractItem) => contractItem.product === products[productIndex])
            .sort((a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime())

          onDateChange('', !_.isEmpty(sortedContracts) ? sortedContracts[0].endDate : '')
        } else {
          tempMap[products[productIndex]] = 1
          setIsContractError(true)
          setContractError('No valid contract found for this product. Please remove the product or set the manually')
          setNoContractFound(true)
          setIsExpiry(true)
          onDateChange('', '')
        }
      }
      setContractDatForSelectedProducts(temp)
      setProductContractMap({ ...productContractMap, ...tempMap })
    }
  }

  return (
    <>
      <Toast
        isVisible={isSuccessVisible}
        text={EditResourcesStrings.resourceAddedSuccess}
        textId="add-resources-success-bid-js"
        onDismiss={() => setIsSuccessVisible(false)}
        type="success"
      />
      <Toast
        isVisible={isErrorVisible}
        text={apiErrors?.join(', ')}
        textId="add-resources-toast-error-bid-js"
        onDismiss={() => setIsErrorVisible(false)}
        type="error"
      />
      <Toast
        isVisible={isInputErrorVisible}
        text={'please select atleast one API product'}
        textId={'api-product-toast-error-bid-js'}
        onDismiss={() => setIsInputErrorVisible(false)}
        type="error"
      />
      <Toast
        isVisible={isContractError}
        text={contractError}
        textId={'equipment-contract-toast-error-bid-js'}
        onDismiss={() => setIsContractError(false)}
        type="error"
      />
      <Toast
        isVisible={isAddWithoutExpiryError}
        text={'Please remove invalid product or set the expiry date'}
        textId={'equipment-add-contract-no-expiry-toast-error-bid-js'}
        onDismiss={() => setAddWithoutExpiryError(false)}
        type="error"
      />
      <HeaderRow>
        <RowContentSmall>BuildingId</RowContentSmall>
        <RowContentExtraSmall>Groups</RowContentExtraSmall>
        <RowContentLarge>Products</RowContentLarge>
        <RowContentMedium>Expiry</RowContentMedium>
        <RowContentSmall>Action</RowContentSmall>
      </HeaderRow>
      <TableRow>
        <Row>
          <RowExpand onClick={() => setIsIconExpand(!isIconExpand)}>
            {isIconExpand ? (
              <>
                <IconExpand />
              </>
            ) : (
              <>
                <IconHide />
              </>
            )}
          </RowExpand>
          <RowContentSmall>{building.id}</RowContentSmall>
          <RowContentExtraSmall>{building.groups.length}</RowContentExtraSmall>
          <RowContentLarge>
            <ProductSelectionAuto>
              <ProductOptions
                defaultProducts={[]}
                onChange={(products) => {
                  onProductsChange(products)
                  setProductExpiryDate(products)
                  setCurrentProduct(products[products.length - 1])
                }}
                resourceType={'building'}
              />
            </ProductSelectionAuto>
          </RowContentLarge>
          <RowContentMedium>
            <SwitchContainer>
              <DatePickerContainer>
                <DatePicker
                  onChange={onDateChange}
                  disabled={!isExpires}
                  disabledDate={disabledDate}
                  value={date !== '' ? moment(date) : null}
                />
              </DatePickerContainer>
              {!noContractFound &&
                productsSelected.length != 0 &&
                Object.keys(contractDatForSelectedProducts).length !== 0 && (
                  <UpdateCounterBuilding>
                    {'+' + Object.keys(contractDatForSelectedProducts).length}
                  </UpdateCounterBuilding>
                )}
            </SwitchContainer>
          </RowContentMedium>
          <RowContentSmall>
            <AddContainer>
              <AddIcon onClick={onClickAdd} />
              {isSaving && (
                <Loader>
                  <RingSpinner />
                </Loader>
              )}
              {isOk && <OkIcon style={{ width: '20px', height: '20px', alignSelf: 'center' }} />}
            </AddContainer>
          </RowContentSmall>
        </Row>
      </TableRow>
      {!isIconExpand && (
        <GroupResourceList
          organizationId={organizationId}
          building={building}
          isLatest={isLatest}
          contract={contract}
        />
      )}
    </>
  )
}

export default BuildingResourceList
