export const orgTypeLegend = {
  sandbox: 'Sandbox',
  internal: 'Internal',
  external: 'External ',
}

export const productLegend = {
  callgiving: 'Elevator Call API',
  robotcall: 'Service Robot API',
  rtm: 'Equipment Status API',
  serviceinfo: 'Service Info API',
}

export const GraphData = {
  ringChartTitle: 'Organization Type Details',
  productCountTitle: 'API Products Details',
  orgProductTitle: 'Partners - API Product',
  graphTitle: 'Number of partners for each API product',
  search: 'Search',
  selectedOrgType: ['external'],
  apiProductType: ['callgiving', 'robotcall', 'rtm', 'serviceinfo'],
  organization: 'Partners',
  apiProduct: 'API Products',
  primaryFilter: 'Select Primary Filter*',
  secondaryFilter: 'Select Secondary Filter',
  viewDetails: 'View Details',
  orgProductDec:
    "This graph gives the total number of partners using our API product.<br>Please click on the 'View details' button to see more insights regarding the Partners serving in a particular country with the total number of equipment integrated with the particular API product.",
  country: 'Country',
  countryWiseProduct: 'Country Wise API Products',
  countryProductTitle: 'Country - API Products',
  countryProductDec:
    'This graph displays the total number of API Products used in each country. Currently, it highlights the top 5 countries with the highest API Product usage. <br>The 6th bar represents the remaining countries combined, showing the total API product count for those countries.',
}

export const orgProductDetails = {
  header: 'Product-Wise Partner Details',
  export: 'Export',
  loadingList: 'Loading Organization Product details list...',
  partnername: 'Partner Name',
  country: 'Country',
  productName: 'Product Name',
  equipmentCount: 'Equipment Count',
}

export const ApiProductMappings: Map<string, string> = new Map([
  ['callgiving', 'Elevator Call API'],
  ['robotcall', 'Service Robot API'],
  ['serviceinfo', 'Service Info API'],
  ['rtm', 'Equipment Status API'],
])

export const headers = [
  { label: 'Partner Name', key: 'partnername' },
  { label: 'Country', key: 'country' },
  { label: 'Product Name', key: 'productname' },
  { label: 'Equipment Count', key: 'equipmentscount' },
]
