// TODO: first letter is small of all
export const Solution = {
  SelectSol: 'Search by',
  SolutionCat: 'SOLUTIONS CATALOGUE',
  IntegratorName: 'Integrator Name',
  PartnerName: 'Partner Name',

  SolutionName: 'Solution Name',
  Refine: 'Refine by',
  Region: 'REGION',
  Con: 'COUNTRY',
  Category: 'CATEGORY',
  Tier: 'TIER',
  Api: 'API',
  SearchReg: 'Search by Region',
  Catalog: 'Catalogue',
}

export const Region = {
  Apa: 'APA',
  Cne: 'CNE',
  Sema: 'SEMA',
  Ena: 'ENA',
}

export const Country = {
  Aus: 'Australlia',
  Cha: 'China',
  Fin: 'Finland',
  Gem: 'Germany',
  Ind: 'India',
}

export const Tier = {
  T1: 'Tier 1',
  T2: 'Tier 2',
  T3: 'Tier 3',
}

export const Api = {
  Call: 'Elevator Call API',
  Robot: 'Service Robot API',
  Status: 'Equipment Status API',
  Info: 'Service Info Api',
}

export const SolutionDetails = {
  Solution: 'Solution Highlights',
  Company: 'Company Info',
  Center: 'Solution Center of Knowledge',
  Contactor: 'Solution Contactor',
  AnyQueries: 'Any Queries',
  GetInTouch: 'Want to get in touch with the Integrator? Here is how you can reach them....',
  ContactUs: 'CONTACT US',
  Question: 'Have any questions ? we would love to hear from you',
  Button1: 'INTEGRATOR INFORMATION DOCUMENTS FOR SALE',
  Header1: 'Main point of contact',
  Header2: 'Marketing; Sales & tendering; Order-booking',
  Header3: 'Genral support & feedback',
}
