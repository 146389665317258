import React, { FC, useState, useEffect, useContext } from 'react'
import { CreateFormStrings, PartnerStrings } from '../../../../shared/strings/PartnersContent'
import Modal from '../../../../shared/components/ModalPopUp'
import { ErrorMessage, Toast } from '../../../../shared/components'
import {
  ButtonContainer,
  CancelButton,
  Container,
  FormLabel,
  ImageBtn,
  ImageName,
  InputName,
  LabelContainer,
  PreviewImage,
  SaveButton,
  SelectContainer,
  StyledLine,
  TextareaDesc,
  TypeSelector,
  UploadBtn,
  UploadFileSection,
  UpperSection,
} from './Styles'
import { Col, Row } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import {
  APP_NAME_MAX_LENGTH,
  APP_NAME_MIN_LENGTH,
  APP_NAME_REG,
  COMPANY_INFO_MAX_LENGTH,
  COMPANY_INFO_MIN_LENGTH,
  PARTNER_WEBSITE_LINK_REGX,
} from '../../../../shared/constants/auth'
import FileUpload from '../../../../shared/components/FileUpload/FileUpload'
import { getCountryList, getLanguages, validateWebsiteLink } from '../../../../shared/utils/salespage'
import { SalesPageAPI } from '../../../../shared/api/salespage'
import { ALLOWED_FILE_EXT } from '../../../../shared/constants/salespage'
import { PartnerContext } from '../../AddNewParnter/state'

interface EditCompanyInfoProps {
  isEditCompanyInfoOpen: boolean
  closeEditCompanyInfo: () => void
  integratorInfo?: any
  isCreateErrorVisible: boolean | false
  setIsCreateErrorVisible: (data: boolean) => void
  createIntegratorErrorMessage?: string | ''
  setCreateIntegratorErrorMessage: (msg: string) => void
  invalidateIntegratorData: () => void
}
const EditCompanyInfo: FC<EditCompanyInfoProps> = (props) => {
  const [logo, setLogo] = useState<any | null>(null)
  const [currentImageUrl, setCurrentImageUrl] = useState<any | null>(null)
  const [dataUri, setDataUri] = useState<string | ''>('')
  const [preData, setPreData] = useState<any>({})
  const [isSuccess, setIsSuccess] = useState<boolean | false>(false)
  const [successMsg, setsuccessMsg] = useState<string | ''>('')
  const [formErr, setFormErr] = useState<any>({
    logo: {
      error: false,
      message: 'Please select upload logo image',
    },
  })
  const { masterData } = useContext(PartnerContext)
  const {
    isEditCompanyInfoOpen,
    closeEditCompanyInfo,
    integratorInfo,
    isCreateErrorVisible,
    setIsCreateErrorVisible,
    createIntegratorErrorMessage,
    setCreateIntegratorErrorMessage,
    invalidateIntegratorData,
  } = props
  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
    reset,
  } = useForm({
    defaultValues: {
      partnerName: integratorInfo?.partnerName,
      companyInfo: preData?.companyInfo ? preData?.companyInfo : '',
      websiteLink: preData?.websiteLink ? preData?.websiteLink : '',
      language: preData?.language ? preData?.language : [],
      country: preData?.country ? preData?.country : '',
    },
  })

  useEffect(() => {
    setTimeout(() => setPreData({ ...integratorInfo }), 1000)
  }, [isEditCompanyInfoOpen])

  useEffect(() => {
    reset({
      partnerName: preData?.partnerName,
      companyInfo: preData?.companyInfo,
      websiteLink: preData?.websiteLink,
      country: preData?.country,
      language: preData?.language?.join(',')?.toLowerCase()?.split(','),
    })

    setCurrentImageUrl(preData?.image)
    setDataUri(preData?.image)
  }, [preData, isEditCompanyInfoOpen])

  const validateLogo = () => {
    let isValid = true
    const tempError = { ...formErr }
    if (dataUri?.length == 0) {
      tempError.logo.error = true
      tempError.logo.errorMessage = CreateFormStrings.uploadLogoRequiredError
      isValid = false
    } else {
      tempError.logo.error = false
    }

    setFormErr(tempError)
    return isValid
  }

  const fileToDataUri = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event: any) => {
        resolve(event?.target?.result)
      }
      reader.readAsDataURL(file)
    })

  const validateSelectedFile = (logo: any) => {
    console.log('dimentions:', logo?.offsetHeight, logo?.offsetWidth)
    const MIN_FILE_SIZE = 0 // 1MB
    const MAX_FILE_SIZE = 200 // 5MB

    if (!logo) {
      setFormErr({ logo: { error: true, message: 'Please choose a file' } })
      return
    }

    const fileExt = logo?.name?.split('.')?.pop()?.toLowerCase()
    if (!ALLOWED_FILE_EXT?.includes(fileExt)) {
      setFormErr({
        logo: {
          error: true,
          message: `Please select valid file type, Valid File Types: ${ALLOWED_FILE_EXT?.join(', ')}`,
        },
      })
      return
    }

    const fileSizeKiloBytes = logo.size / 1024

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setFormErr({ logo: { error: true, message: 'File size is greater than maximum limit' } })
      return
    }

    setFormErr({ logo: { error: false, message: '' } })
    return true
  }

  const onUploadLogo = (e: any) => {
    console.log('filles:', e?.target?.files[0])
    const isValidImage = validateSelectedFile(e?.target?.files[0])
    if (!isValidImage) {
      return false
    }
    setLogo(e?.target?.files[0])
    setCurrentImageUrl(URL.createObjectURL(e?.target?.files[0]))
    fileToDataUri(e?.target?.files[0]).then((dataUri: any) => {
      setDataUri(dataUri)
    })
    return true
  }

  const removeLogo = () => {
    setDataUri('')
    setLogo(null)
    setCurrentImageUrl(null)
  }

  const onSubmit = async (data: any) => {
    console.log('Data: ', data)
    const isValid = validateLogo()
    if (!isValid) {
      return false
    }
    data.imageLogo = dataUri
    data.partnerStep = 1
    console.log('error data', formErr, data)

    const resopnse = await SalesPageAPI.editIntegrator(data, preData?.partnerId)
    console.log('response', resopnse)
    if (!resopnse.ok) {
      const newRes = await resopnse.json()
      setIsCreateErrorVisible(true)
      setCreateIntegratorErrorMessage(newRes?.message)
    } else if (resopnse.status === 202) {
      const newRes = await resopnse.json()
      setsuccessMsg('Intergrator updated successfully.')
      setIsSuccess(true)
      closeEditCompanyInfo()
      invalidateIntegratorData()
    }
    return true
  }

  const filterOption = (input: any, option: any) => {
    if (option.children) {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false
    } else if (option.label) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false
    }
    return false
  }

  return (
    <>
      <Toast isVisible={isSuccess} text={successMsg} onDismiss={() => setIsSuccess(false)} type="success" />
      <Modal
        key="edit-company-info"
        isOpen={isEditCompanyInfoOpen}
        title={PartnerStrings.editCompanyInfoAndContactDetails}
        onClose={closeEditCompanyInfo}
        width={'850px'}
        height={'300px'}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <UpperSection>
              <Row gutter={24}>
                <Col span={16}>
                  <Row gutter={20}>
                    <Col span={24}>
                      <FormLabel htmlFor="partner-name-input-js" id="partner-name-input-label-js">
                        {CreateFormStrings.partnerName}*
                      </FormLabel>
                      <Controller
                        render={({ field }) => <InputName id="partner-name-input-js" {...field} disabled={false} />}
                        name="partnerName"
                        control={control}
                        rules={{
                          required: true,
                          pattern: { value: APP_NAME_REG, message: CreateFormStrings.partnerNameWhiteSpaceError },
                          maxLength: {
                            value: APP_NAME_MAX_LENGTH,
                            message: CreateFormStrings.partnerNameLengthError,
                          },
                          minLength: {
                            value: APP_NAME_MIN_LENGTH,
                            message: CreateFormStrings.partnerNameLengthError,
                          },
                        }}
                      />
                      {errors.partnerName && (
                        <ErrorMessage>
                          {errors.partnerName.message || CreateFormStrings.partnerNameRequiredError}
                        </ErrorMessage>
                      )}
                    </Col>
                  </Row>
                  <Row gutter={20}>
                    <Col span={24}>
                      <FormLabel htmlFor="website-link-input-js" id="website-link-input-label-js">
                        {CreateFormStrings.websiteLink}*
                      </FormLabel>
                      <Controller
                        render={({ field }) => <InputName id="website-link-input-js" {...field} disabled={false} />}
                        name="websiteLink"
                        control={control}
                        rules={{
                          required: true,
                          pattern: PARTNER_WEBSITE_LINK_REGX,
                          validate: validateWebsiteLink,
                        }}
                      />
                      {errors.websiteLink && (
                        <ErrorMessage>
                          {errors.websiteLink.message || CreateFormStrings.websiteLinkRequiredError}
                        </ErrorMessage>
                      )}
                    </Col>
                  </Row>
                  <Row gutter={20}>
                    <Col span={24}>
                      <SelectContainer>
                        <LabelContainer>
                          <FormLabel htmlFor="edit-language-selector-js" id="edit-language-label-js">
                            {CreateFormStrings.selectLanguages}*
                          </FormLabel>
                        </LabelContainer>
                        <Controller
                          render={({ field }) => (
                            <TypeSelector
                              showSearch
                              mode="multiple"
                              id="edit-language-selector-js"
                              {...field}
                              filterOption={filterOption}
                            >
                              {getLanguages(masterData?.language)}
                            </TypeSelector>
                          )}
                          name="language"
                          control={control}
                          rules={{
                            required: true,
                          }}
                        />
                        {errors.language && (
                          <ErrorMessage>
                            {(errors.language && (errors.language as any).message) ||
                              CreateFormStrings.languageRequiredError}
                          </ErrorMessage>
                        )}
                      </SelectContainer>
                    </Col>
                  </Row>
                  <Row gutter={20}>
                    <Col span={24}>
                      <SelectContainer>
                        <LabelContainer>
                          <FormLabel htmlFor="edit-country-js" id="edit-country-label-js">
                            {CreateFormStrings.selectCountry}*
                          </FormLabel>
                        </LabelContainer>
                        <Controller
                          render={({ field }) => (
                            <TypeSelector
                              showSearch
                              id="edit-country-selector-js"
                              {...field}
                              filterOption={filterOption}
                            >
                              {getCountryList(masterData?.country)}
                            </TypeSelector>
                          )}
                          name="country"
                          control={control}
                          rules={{
                            required: true,
                          }}
                        />
                        {errors.country && (
                          <ErrorMessage>
                            {(errors.country && (errors.country as any).message) ||
                              CreateFormStrings.countryRequiredError}
                          </ErrorMessage>
                        )}
                      </SelectContainer>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24}>
                      <FormLabel htmlFor="app-company-info-input-js">{CreateFormStrings.companyInfo}*</FormLabel>
                      <Controller
                        render={({ field }) => <TextareaDesc id="company-info-input-js" {...field} disabled={false} />}
                        name="companyInfo"
                        control={control}
                        rules={{
                          required: true,
                          maxLength: {
                            value: COMPANY_INFO_MAX_LENGTH,
                            message: CreateFormStrings.companyInfoMaxLengthError,
                          },
                          minLength: {
                            value: COMPANY_INFO_MIN_LENGTH,
                            message: CreateFormStrings.companyInfoMinLengthError,
                          },
                        }}
                      />
                      {errors.companyInfo && (
                        <ErrorMessage>
                          {(errors.companyInfo && (errors.companyInfo as any).message) ||
                            CreateFormStrings.companyInfoRequiredError}
                        </ErrorMessage>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={4}>
                  <UploadFileSection>
                    {currentImageUrl === null && (
                      <FileUpload label={'Upload File*'} onChange={onUploadLogo} height={'200px'} />
                    )}
                    {formErr?.logo?.error && <ErrorMessage>{formErr?.logo?.errorMessage}</ErrorMessage>}
                    <ImageBtn>
                      {currentImageUrl?.length > 0 && <PreviewImage src={currentImageUrl} />}
                      <ImageName>{logo?.name}</ImageName>
                      {dataUri?.length > 0 && (
                        <UploadBtn type="primary" onClick={removeLogo}>
                          Remove Image
                        </UploadBtn>
                      )}
                    </ImageBtn>
                  </UploadFileSection>
                </Col>
              </Row>
            </UpperSection>
            <StyledLine />
            <ButtonContainer>
              <CancelButton onClick={closeEditCompanyInfo}>{PartnerStrings.cancel}</CancelButton>
              <SaveButton htmlType="submit" onClick={validateLogo}>
                {PartnerStrings.saveChanges}
              </SaveButton>
            </ButtonContainer>
          </Container>
        </form>
      </Modal>
    </>
  )
}

export default EditCompanyInfo
