import React, { FC } from 'react'
import { useQuery } from 'react-query'
import { StatisticsAPI } from '../../shared/api/statistics'
import {
  KpiNamesGeneral,
  KpiNamesAPIExplorer,
  KpiNamesAPIConsumer,
  userData,
  orgUsersData,
} from '../../shared/models/statistics'
import { MessageContainer, TilesContainer, TextTile, NumberText, DescriptionText, StyledLine, Stats } from './Styles'
import { H4 } from '../../shared/components'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../Paths'
import { ReactComponent as ErrorIcon } from '../../assets/icons-red-warning.svg'
import awsConfig from '../../shared/utils/awsConfig'
import { statDashboardEnvs } from '../../shared/constants/common'

const Statistics: FC = () => {
  const KpiQuery = useQuery(StatisticsAPI.queryNames.GET_KPI, () => StatisticsAPI.getKpi())
  const OrgsAndUsersQuery = useQuery(StatisticsAPI.queryNames.GET_ORGANIZATIONS_AND_USERS, () =>
    StatisticsAPI.getOrgsWithUsers(false)
  )

  const getAllRegisteredUsersCount = (): number => {
    const userids = [] as string[]
    OrgsAndUsersQuery.data.map((orgs: orgUsersData) => {
      orgs.users.map((user: userData) => {
        if (user.userId !== undefined) {
          const userId = user.userId.replace(/"/g, "'")
          if (userids.indexOf(userId) === -1) {
            userids.push(userId)
          }
        }
      })
    })
    return userids.length
  }

  const getKpiTiles = (kpiNames: Map<string, string>) => {
    const kpiTiles = Array.from(kpiNames.keys()).map((key) => {
      return (
        <TextTile key={key}>
          <NumberText> {KpiQuery.data[key] ?? 0} </NumberText>
          <DescriptionText> {kpiNames.get(key)} </DescriptionText>
        </TextTile>
      )
    })

    return kpiTiles
  }

  const showStatistics = () => {
    return (
      <>
        {KpiQuery.isLoading && <MessageContainer> Loading statistics </MessageContainer>}
        {KpiQuery.error && <MessageContainer> Error loading statistics </MessageContainer>}
        {OrgsAndUsersQuery.isLoading && <MessageContainer> Loading organizations and users </MessageContainer>}
        {KpiQuery.data && (
          <>
            <Stats>
              <Link style={{ color: 'white', marginRight: '20px' }} to={Paths.Statistics.path}>
                Statistics
              </Link>

              {!statDashboardEnvs.includes(awsConfig?.environment) && (
                <Link style={{ color: 'white' }} to={Paths.StatDashboard.path}>
                  Dashboard
                </Link>
              )}
            </Stats>

            <H4>General</H4>
            <StyledLine />
            <TilesContainer>
              {!OrgsAndUsersQuery.isLoading && (
                <TextTile>
                  <NumberText>
                    {OrgsAndUsersQuery.data && getAllRegisteredUsersCount()}
                    {OrgsAndUsersQuery.error && <ErrorIcon />}
                  </NumberText>
                  <DescriptionText>All Registered Users with personal applications</DescriptionText>
                </TextTile>
              )}
              {getKpiTiles(KpiNamesGeneral)}
            </TilesContainer>
            <H4>API Explorer</H4>
            <StyledLine />
            <TilesContainer>{getKpiTiles(KpiNamesAPIExplorer)}</TilesContainer>
            <H4>API Consumer</H4>
            <StyledLine />
            <TilesContainer>{getKpiTiles(KpiNamesAPIConsumer)}</TilesContainer>
          </>
        )}
      </>
    )
  }

  return <>{showStatistics()}</>
}

export default Statistics
