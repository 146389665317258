import { Col, Row } from 'antd'
import React, { FC } from 'react'
import { ErrorMessage } from '../../../../shared/components'
import { CreateFormStrings } from '../../../../shared/strings/PartnersContent'
import { Contact } from '../../Models'
import { FormLabel, InputColumn, InputName, StyledLine, InputsContainer } from '../Styles'

const PartnerContactDetails: FC<{
  index: number
  contact: Contact
  onInputChange: (e: any, index: number, name: string) => void
  onPhoneNumberChange: (e: any, index: number, name: string) => void
  formErr?: any
}> = (props) => {
  const { index: currentContactIndex, contact, onInputChange, onPhoneNumberChange, formErr } = props

  return (
    <>
      <InputsContainer>
        <Row gutter={24}>
          <Col span={8}>
            <InputColumn>
              <FormLabel htmlFor="first-name-input-js" id="first-name-input-label-js">
                {CreateFormStrings.firstName}*
              </FormLabel>
              <InputName
                id="first-name-input-js"
                placeholder={'Enter First Name'}
                onChange={(e: any) => onInputChange(e, currentContactIndex, 'firstName')}
                disabled={false}
                value={contact?.firstName}
              />
              {formErr?.length > 0 && formErr[currentContactIndex].firstName?.error && (
                <ErrorMessage>{formErr[currentContactIndex].firstName?.message}</ErrorMessage>
              )}
            </InputColumn>
          </Col>
          <Col span={8}>
            <InputColumn>
              <FormLabel htmlFor="last-name-input-js" id="last-name-input-label-js">
                {CreateFormStrings.lastName}*
              </FormLabel>
              <div>
                <InputName
                  id="last-name-input-js"
                  placeholder={'Enter last Name'}
                  onChange={(e: any) => onInputChange(e, currentContactIndex, 'lastName')}
                  disabled={false}
                  value={contact?.lastName}
                />
                {formErr?.length > 0 && formErr[currentContactIndex].lastName?.error && (
                  <ErrorMessage>{formErr[currentContactIndex].lastName?.message}</ErrorMessage>
                )}
              </div>
            </InputColumn>
          </Col>
          <Col span={8}>
            <InputColumn>
              <FormLabel htmlFor="email-input-js" id="email-input-label-js">
                {CreateFormStrings.email}*
              </FormLabel>
              <div>
                <InputName
                  id="email-input-js"
                  placeholder={'Enter Email'}
                  onChange={(e: any) => onInputChange(e, currentContactIndex, 'email')}
                  disabled={false}
                  value={contact?.email}
                  type="email"
                  required
                />
                {formErr?.length > 0 && formErr[currentContactIndex].email?.error && (
                  <ErrorMessage>{formErr[currentContactIndex].email?.message}</ErrorMessage>
                )}
              </div>
            </InputColumn>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <InputColumn>
              <FormLabel htmlFor="phone-number-input-js" id="phone-number-input-label-js">
                {CreateFormStrings.phoneNumber}*
              </FormLabel>
              <div>
                <InputName
                  id="phone-number-input-js"
                  placeholder={'Enter phone number'}
                  onChange={(e: any) => onPhoneNumberChange(e, currentContactIndex, 'phoneNumber')}
                  disabled={false}
                  value={contact?.phoneNumber}
                />
                {formErr?.length > 0 && formErr[currentContactIndex].phoneNumber?.error && (
                  <ErrorMessage>{formErr[currentContactIndex].phoneNumber?.message}</ErrorMessage>
                )}
              </div>
            </InputColumn>
          </Col>
          <Col span={8}>
            <InputColumn>
              <FormLabel htmlFor="job-title-input-js" id="job-title-input-label-js">
                {CreateFormStrings.jobTitle}*
              </FormLabel>
              <div>
                <InputName
                  id="job-title-input-js"
                  placeholder={'Enter Job Title'}
                  onChange={(e: any) => onInputChange(e, currentContactIndex, 'jobTitle')}
                  disabled={false}
                  value={contact?.jobTitle}
                />
                {formErr?.length > 0 && formErr[currentContactIndex].jobTitle?.error && (
                  <ErrorMessage>{formErr[currentContactIndex].jobTitle?.message}</ErrorMessage>
                )}
              </div>
            </InputColumn>
          </Col>
        </Row>
      </InputsContainer>
      <StyledLine />
    </>
  )
}

export default PartnerContactDetails
