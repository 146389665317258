import countryLookup from 'country-code-lookup'
import React, { FC } from 'react'
import { useQuery } from 'react-query'
import { ApiProductMappings } from '../../../../shared/models/product'
import { ProductsAPI } from '../../../../shared/api/products'
import { ResourcesAPI } from '../../../../shared/api/resources'
import { productInfo } from '../../../../shared/constants/product'
import { Organization } from '../../../../shared/models/organization'
import { Resource } from '../../../../shared/models/resource'
import ApplicationCount from '../ApplicationCount'
import MemberCount from '../MemberCount'

import {
  ApplicationCount as ApplicationCountStyle,
  BuildingsCount,
  Country,
  CountryHeader,
  EquipmentCount,
  MembersCount,
  MembersHeader,
  NumberValue,
  NumberValueHeader,
  OrganizationDetails,
  OrganizationID,
  OrganizationIDHeader,
  RegisteredAPIs,
  RegisteredAPIsHeader,
  ResourcesHeader,
} from '../Styles'

type Props = {
  organization: Organization
}

const RowExtension: FC<Props> = ({ organization }) => {
  const resourceQueryName = `${ResourcesAPI.queryNames.GET_BY_ORGANIZATION_ID}${organization.id}`
  const productQueryName = `${ProductsAPI.queryNames.GET_BY_ORGANIZATION_ID}${organization.id}`

  const {
    isLoading: resourceIsLoading,
    error: resourceError,
    data: resourceData,
  } = useQuery(resourceQueryName, () => ResourcesAPI.getByOrganizationId(organization.id))

  const {
    isLoading: productIsLoading,
    error: productError,
    data: productData,
  } = useQuery(productQueryName, () => ProductsAPI.getProductNamesByOrganizationId(organization.id))

  const getNumberOf = (type: string) => {
    const arrayUniqueByKey = [
      ...new Map(
        resourceData
          .filter((resource: Resource) => resource.resourceId.type === type)
          .map((resource: Resource) => [resource.resourceId.uniqueId, resource])
      ).values(),
    ]
    return arrayUniqueByKey.length
  }

  const numberOfBuildings = resourceIsLoading ? undefined : getNumberOf('building')
  const numberOfEquipments = resourceIsLoading ? undefined : getNumberOf('ken')

  const getAPINames = () => {
    const products = productInfo
      .filter((productInfoItem) => (productData ?? []).includes(productInfoItem.name))
      .map((productInfoItem) => productInfoItem.type)

    products.sort((a, b) => a.localeCompare(b))

    return products.join(', ')
  }

  const getProductsFromVersions = () => {
    const uniqueAPIs = new Set()
    resourceData?.map((oneResource: any) => oneResource.version.forEach(uniqueAPIs.add, uniqueAPIs))
    const products: any[] = []
    const newUniqueAPis = [...uniqueAPIs]
    newUniqueAPis?.map((oneAPI: any) => {
      if (oneAPI.includes('v1')) {
        oneAPI = oneAPI.split('-')[0]
      }
      if (ApiProductMappings.has(oneAPI)) {
        const apiName = ApiProductMappings.get(oneAPI)
        products.push(apiName)
      }
    })
    return products.join(', ')
  }

  if (resourceError || productError) {
    return <p>Error</p>
  }

  return (
    <>
      <OrganizationDetails>
        <OrganizationIDHeader>Organization ID</OrganizationIDHeader>
        <CountryHeader>Country</CountryHeader>
        <RegisteredAPIsHeader>Registered APIs</RegisteredAPIsHeader>
        <ResourcesHeader>Resources</ResourcesHeader>
        <MembersHeader>Members</MembersHeader>
        <OrganizationID id="org-id">{organization.id}</OrganizationID>
        <Country id="org-country">
          {countryLookup.byIso(organization.country ? organization.country : 0)?.country}
        </Country>
        <RegisteredAPIs id="org-registered-api">{productIsLoading ? '–' : getProductsFromVersions()}</RegisteredAPIs>
        <EquipmentCount>
          <NumberValue id="org-equipment-count">{resourceIsLoading ? '–' : numberOfEquipments}</NumberValue>
          <NumberValueHeader>Equipment</NumberValueHeader>
        </EquipmentCount>
        <BuildingsCount>
          <NumberValue id="org-building-count">{resourceIsLoading ? '–' : numberOfBuildings}</NumberValue>
          <NumberValueHeader>{numberOfBuildings && numberOfBuildings > 1 ? 'Buildings' : 'Building'}</NumberValueHeader>
        </BuildingsCount>
        <MembersCount>
          <MemberCount organization={organization} />
        </MembersCount>
        <ApplicationCountStyle>
          <ApplicationCount organization={organization} />
        </ApplicationCountStyle>
      </OrganizationDetails>
    </>
  )
}

export default RowExtension
