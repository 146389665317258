import { color } from '../../../shared/utils/styles'
import styled from 'styled-components'
import { H4 } from '../../../shared/components'
import { Input, Select, Steps } from 'antd'
import Button from '../../../shared/components/Button'
import { ReactComponent as RightActiveArrow } from '../../../assets/icons-pagination-next-active.svg'
import { ReactComponent as LeftActiveArrow } from '../../../assets/icon-pagination-previous-active.svg'
import { ReactComponent as LeftInactiveArrow } from '../../../assets/icon-pagination-previous-passive.svg'
import { ReactComponent as RightInactiveArrow } from '../../../assets/icons-pagination-next-passive.svg'
import { ReactComponent as AddIcon } from '../../../assets/icongraphic.svg'

import TextArea from 'antd/lib/input/TextArea'
import ReactQuill from 'react-quill'

const { Step } = Steps
export const StyledLine = styled.hr`
  border: 0.5px solid ${color.gray70};
  margin-bottom: 0px;
  margin-top: 10px;
  opacity: 0.4;
  width: 100%;
`

export const Underline = styled.div`
  text-decoration: underline;
  text-decoration-color: ${color.gray70};
  padding-top: 1em;
`

export const SingleStep = styled(Step)`
  margin-top: 20px;
`

export const PartnerH4 = styled(H4)`
  margin-left: 8px;
  text-transform: uppercase;
  margin-bottom: 20px;
`

export const PartnerP = styled.p`
  margin-bottom: 4px;
  text-align: left;
  margin-top: 14px;
`
export const NextActiveArrow = styled(RightActiveArrow)`
  margin-right: 4px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-top: 18px;
`

export const PreviousActiveArrow = styled(LeftActiveArrow)`
  margin-left: 4px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-top: 18px;
`

export const NextPassiveArrow = styled(RightInactiveArrow)`
  margin-right: 4px;
  width: 40px;
  height: 40px;
  margin-top: 18px;
  .an {
    fill: none;
  }
  .bn {
    fill: #3d464c;
  }
`

export const PreviousPassiveArrow = styled(LeftInactiveArrow)`
  margin-left: 4px;
  width: 40px;
  height: 40px;
  margin-top: 18px;
  .ap {
    fill: none;
  }
  .bp {
    fill: #3d464c;
  }
`
export const ButtonContainer = styled.div`
  height: 10px;
`
export const ButtonContainer2 = styled.div`
  z-index: 999;
  height: 10px;
`
export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  font-size: 16px;
  color: white;
  align-items: center;
  justify-content: flex-start;
`

export const Wrapper = styled.div`
  background-color: ${color.gray90};
  padding: 10px;
  padding-left: 3em;
  .steps-content {
    min-height: 200px;
    margin-top: 16px;
    //text-align: center;
  }

  .steps-action {
    display: flex;
    margin-top: 10px;
    justify-content: right;
    margin-right: 70px;
  }
  .steps-action1 {
    //display: flex;
    margin: -25px 20px 0 60px;
  }
  [data-theme='dark'] .steps-content {
    margin-top: 16px;
    color: rgba(255, 255, 255, 0.65);
    padding-top: 20px;
  }
`

export const Vl = styled.div`
  border-left: 2px solid ${color.gray80};
  height: 300px;
  padding-left: 20px;
  pagging-right: 20px;
`

interface Props {
  current: number
  value: number
}

export const StepText = styled.span<Props>`
  color: ${color.gray100};
  border: 2px solid ${color.blueBase2};
  border-radius: 5px;
  padding: 8px 13px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 200;
  background-color: ${(props) => (props?.current > props?.value ? color.blueBase2 : '')};
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin-top: 5px;
  padding-top: 2em;
`
export const StepContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 5px;
  padding-top: 1em;
  justify-content: space-around;
`
export const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 5px;
  padding-top: 2em;
`
export const RequiredFieldsText = styled.div`
  font-size: 11px;
  margin-top: 4px;
`

export const UploadFileSection = styled.section`
  width: 16em;
  height: 100%;
  //background-color: blue;
`
export const BottomStyleLine = styled(StyledLine)``

export const SolutionContainer = styled.div``
export const SolutionContainerItem = styled.div``
export const FormSection = styled.section`
  width: 70%;
  height: 100%;
`
export const TypeSelector = styled(Select)`
  width: 250px;
  margin-bottom: 35px;
  overflow-y: 'scroll';
  margin-right: 70px;
`

export const InputsContainer = styled.div`
  flex-grow: 1;
  margin-right: 16px;
  //width: 40em;
  width: 100%;
`

export const InputRowContainer = styled.div`
  display: flex;
  margin-bottom: 32px;
`
export const InputColumnUp = styled.div`
  margin-bottom: 9%;
`
export const InputColumn = styled.div`
  //width: 100%;
  margin: 0 auto;
`
export const InputColumnRight = styled.div``

export const EditorColumn = styled.div`
  padding-left: 10px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`

export const FormLabel = styled.label`
  margin-bottom: 3px;
  margin-top: 10px;
  display: block;
  text-aligh: left;
`

export const InputName = styled(Input)`
  color: ${color.gray75};
  width: 96%;
`

export const CancelButton = styled(Button)`
  width: 250px;
`

export const CreateButton = styled(Button)`
  width: 208px;
`

export const FormButton = styled(Button)`
  margin-right: 10px;
  border: 1px solid #fff;
  background-color: ${color.gray90};
`
export const InputDescription = styled(TextArea)`
  border: 1px solid ${color.blueTint20};
  width: 92%;
`

export const Editor = styled(ReactQuill)`
  border: 1px solid ${color.gray20};
  height: 100px;
  width: 84%;
  margin-bottom: 18px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .ql-container {
    background-color: white;
    color: black;
    overflow: visible;
    overflow-y: auto;
    max-height: 100px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .ql-formats {
    color: white;
  }

  .ql-toolbar {
    background-color: transparent;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 2px;
    border: 0px solid transparent;
  }
`

export const PartnersSolutionSection = styled.div`
  margin-top: 50px;
  margin-bottom: 60px;
`

export const AddMoreSolutionButton = styled(Button)`
  background-color: ${color.blueBase};
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
}  
`

export const AddIconSolution = styled(AddIcon)`
  width: 20px;
  height: 20px;
`

export const ArrowButton = styled(Button)`
  background-color: ${color.blueBase};
}  
`
