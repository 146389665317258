import React, { FC, useEffect, useLayoutEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useLocation, BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { useAuth, AuthProvider } from '../shared/contexts/authContext'
import { GlobalStyle, Layout, BannerText, ServiceBreakBanner, InternalOrgBanner } from './Styles'
import Footer from './Footer'
import FooterMenu from './FooterMenu'
import Header from './Header'
import Dashboard from '../Dashboard'
import FrontPage from '../Public/FrontPage'
import ProtectedRoute from '../shared/components/ProtectedRoute'
import NotFoundPage from '../shared/pages/NotFoundPage'
import QuickStart from '../Public/QuickStart'
import KONEAPIs from '../Public/KONEAPIs'
import InspirationPage from '../Public/Inspiration'
import SmarterCitiesArticle from '../Public/Inspiration/SmarterCitiesArticle'
import EcosystemArticle from '../Public/Inspiration/EcosystemArticle'
import TermsAndConditions from '../Public/TermsAndConditions'
import PrivacyStatement from '../Public/PrivacyStatement'
import {
  ElevatorCallApiPage,
  ServiceRobotApiPage,
  EquipmentStatusApiPage,
  ServiceInfoApiPage,
} from '../Public/KONEAPIs/KONEAPIsIndividualPages'
import ErrorBoundary from '../shared/errorBoundary'
import { FooterMenuStrings } from '../shared/strings/FooterContent'
import { ErrorPageStrings } from '../shared/strings/ErrorPageContent'
import awsConfig from '../shared/utils/awsConfig'
import { Feature, isFeatureEnabled } from '../shared/utils/featureFlags'
import { useIsDashboard } from '../shared/hooks/useIsDashboard'
import IntergratedSolutions from '../Public/SolutionsList'
import SolutionDetailsPage from '../Public/SolutionDetails'
import MasterDataProvider from '../Public/SolutionsList/state'

const basePath = process.env.PUBLIC_URL

const ScrollToTop = () => {
  const { pathname } = useLocation()
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const Content: FC = () => {
  const { currentOrganization } = useAuth()
  const isDashboardView = useIsDashboard()

  const serviceBreakMessage = awsConfig?.serviceBreakMessage
    ? awsConfig.serviceBreakMessage
    : ErrorPageStrings.defaultServiceBreakMessage

  const serviceBreakHeader =
    isFeatureEnabled(Feature.ServiceBreak) || isFeatureEnabled(Feature.ShowServiceBreakBanner) ? (
      <ServiceBreakBanner data-nosnippet>
        <BannerText>{serviceBreakMessage}</BannerText>
      </ServiceBreakBanner>
    ) : (
      ''
    )

  const internalOrgHeader =
    currentOrganization?.type === 'internal' && isDashboardView ? (
      <InternalOrgBanner>
        <BannerText>You are exploring internal organization!</BannerText>
      </InternalOrgBanner>
    ) : (
      ''
    )

  return (
    <>
      <GlobalStyle />
      {serviceBreakHeader}
      {internalOrgHeader}
      <Layout>
        <div id="side-panel-mounting-point"></div>
        <Header />
        <Layout.Content>
          <Switch>
            <Route exact path="/">
              <ScrollToTop />
              <FrontPage />
            </Route>
            <Route exact path="/getting-started">
              <ScrollToTop />
              <QuickStart />
            </Route>
            <Route exact path="/inspiration">
              <ScrollToTop />
              <InspirationPage />
            </Route>
            <Route exact path="/inspiration/smarter-cities">
              <ScrollToTop />
              <SmarterCitiesArticle />
            </Route>
            <Route exact path="/inspiration/ecosystem">
              <ScrollToTop />
              <EcosystemArticle />
            </Route>
            <ProtectedRoute path="/dashboard">
              <Dashboard />
            </ProtectedRoute>
            <Route exact path="/terms-and-conditions">
              <ScrollToTop />
              <TermsAndConditions />
            </Route>
            <Route exact path="/privacy-statement">
              <ScrollToTop />
              <PrivacyStatement />
            </Route>
            <Route exact path="/kone-apis">
              <ScrollToTop />
              <KONEAPIs />
            </Route>
            <Route exact path="/solution-details/:solutionId">
              <ScrollToTop />
              <MasterDataProvider>
                <SolutionDetailsPage />
              </MasterDataProvider>
            </Route>
            <Route exact path="/integrated-solutions">
              <ScrollToTop />
              <MasterDataProvider>
                <IntergratedSolutions />
              </MasterDataProvider>
            </Route>
            <Route exact path="/elevator-call-api">
              <ElevatorCallApiPage />
            </Route>
            <Route exact path="/service-robot-api">
              <ServiceRobotApiPage />
            </Route>
            <Route exact path="/equipment-status-api">
              <EquipmentStatusApiPage />
            </Route>
            <Route exact path="/service-info-api">
              <ServiceInfoApiPage />
            </Route>
            <Route>
              <NotFoundPage withMargin={true} />
            </Route>
          </Switch>
        </Layout.Content>
        <FooterMenu />
        <Footer text={FooterMenuStrings.contactInformation} />
      </Layout>
    </>
  )
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const App: FC = () => {
  useEffect(() => {
    window.PORTAL_PAGE_WAS_RENDERED = true
  }, [])

  return (
    <ErrorBoundary withMargin={true}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename={basePath}>
          <AuthProvider>
            <Switch>
              {/* START: Handle OAuth callback URLs redirection.
              React router handles only the redirection, and it does it after
              the actual handling (e.g., tokens management) is done inside of
              the Amplify configure function. */}
              <Route exact path="/login">
                <Redirect to="/dashboard" />
              </Route>
              <Route exact path="/logout">
                <Redirect to="/" />
              </Route>
              {/* END: Handle OAuth callback URLs redirection. */}
              <Route path="/*" component={Content} />
            </Switch>
          </AuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </ErrorBoundary>
  )
}

export default App
