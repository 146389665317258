import { Input, Select } from 'antd'
import styled from 'styled-components'
import Button from '../../../shared/components/Button'
import { color } from '../../../shared/utils/styles'
import { ReactComponent as Refresh } from '../../../assets/icon-refresh.svg'

export const Line = styled.hr`
  margin-bottom: 48px;
  border: 1px solid ${color.gray70};
  opacity: 1;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`

export const FormLabel = styled.div`
  margin-bottom: 8px;
  display: block;
  width: fit-content;
`

export const SaveButton = styled(Button)`
  width: 150px;
  margin-top: 32px;
`

export const TypeSelector = styled(Select)`
  width: 180px;
  margin-right: 20px;
  overflow-y: 'scroll';

  .ant-select-arrow {
    color: ${color.blueBase};
  }
`
export const Header = styled.div`
  display: flex;
  margin-top: 30px;
`

export const Item = styled.div`
  margin-left: 8px;
  margin-bottom: 18px;
  margin-right: auto;
`
export const RightSide = styled.div`
  margin-top: 20px;
  font-weight: 100;
  max-width: 250px;
`
export const RefreshTableButton = styled(Button)`
  margin-top: 10px;
  width: 35px;
  height: 35px;
  margin-left: 8px;
  margin-right: 10px;
`

export const RefreshIcon = styled(Refresh)`
  width: 22px;
  height: 22px;
  margin-top: 6px;
  cursor: pointer;
`
export const DownloadButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2%;
`

export const DownloadButtonName = styled.div`
  padding: 1%;
  border-bottom-style: solid;
  cursor: pointer;
  border-bottom-color: transparent;
  &:hover {
    border-bottom-style: solid;
    border-bottom-color: ${color.blueBase2};
  }
`
export const BoldOptGroupLabel = styled.div`
  font-weight: bold;
  font-size: 15px;
  color: ${color.blueBase2};
`
export const ErrorMessage = styled.p`
  color: ${color.red};
  margin: 0;
`
export const TypeSelectorContainer = styled.p`
  position: relative;
  margin-bottom: 10px;
`

export const StyledLine = styled.hr`
  border: 0.5px solid ${color.gray70};
  margin-bottom: 0px;
  opacity: 0.4;
  width: 100%;
`
export const GraphContainer = styled.div`
  display: flex;
  font-size: 16px;
  color: white;
  width: 85%;
  align-items: baseline;
  // justify-content: flex-start;
  background-color: #1c2226;
  border-radius: 12px;
  margin: 15px;
  padding: 15px;
  height: 45%;
  margin-left: 10%;
  flex-direction: column;
  color: white;
`
export const GraphHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; // ensures vertical alignment
  width: 100%;
`
export const Details = styled(Button)`
  width: 110px;
  color: #fff;
  background: #208cc7;
  border-color: #208cc7;
`
