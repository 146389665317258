export const LOADER_VISIBILITY_MLS = 3000
export const TOOLTIP_VISIBILITY_MLS = 3000
export const TABLE_CONTENT_LENGTH = 15

export const CONTRACT_EXPIRY_MAX_LIMIT = 60
export const CONTRACT_EXPIRY_WARNING_LIMIT = 30
export const CONTRACT_EXPIRY_CRITICAL_LIMIT = 0

const chinaEnv = 'china-prod'
export const movementStartedDisableEnvs = ['prod', chinaEnv]
export const elvatorDoorButtonDisableEnvs: string[] = ['prod', chinaEnv]
export const escalatorMovementDisableEnvs: string[] = []
// export const movCompletedFlag = 'MovCompletedFlag'
export const movCompletedFlagDisableEnvs: string[] = ['china-qa', 'prod', chinaEnv]
export const statDashboardEnvs = ['prod', chinaEnv]
