import React, { FC, useContext, useEffect, useState } from 'react'
import { Button, Col, Row } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage } from '../../../../shared/components'
import {
  PARTNER_NAME_MAX_LENGTH,
  PARTNER_NAME_MIN_LENGTH,
  COMPANY_INFO_MIN_LENGTH,
  COMPANY_INFO_MAX_LENGTH,
  PARTNER_WEBSITE_LINK_REGX,
} from '../../../../shared/constants/auth'
import { CreateFormStrings, PartnerStrings } from '../../../../shared/strings/PartnersContent'
import {
  BottomStyleLine,
  Container,
  FormButton,
  FormLabel,
  FormSection,
  InnerContainer,
  InputColumn,
  InputColumnRight,
  InputDescription,
  InputName,
  InputsContainer,
  PartnerP,
  RequiredFieldsText,
  UploadFileSection,
  Vl,
} from '../Styles'
import { StyledLine } from '../../Styles'
import FileUpload from '../../../../shared/components/FileUpload/FileUpload'
import { PartnerContext } from '../state'
import { ImageBtn, ImageName, InputColumnUp, PreviewImage, TypeSelector, UploadBtn } from './Style'
import { ALLOWED_FILE_EXT } from '../../../../shared/constants/salespage'
import { useHistory } from 'react-router-dom'
import { validateWebsiteLink } from '../../../../shared/utils/salespage'
const { Option } = TypeSelector

interface PartnerInfoProps {
  next: (data: any | null) => void
  current: number | 0
  isCreateErrorVisible: boolean | false
  setIsCreateErrorVisible: (data: boolean) => void
  createIntegratorErrorMessage?: string | ''
  setCreateIntegratorErrorMessage: (msg: string) => void
}

const PartnerInfo: FC<PartnerInfoProps> = (props) => {
  const [logo, setLogo] = useState<any | null>(null)
  const [currentImageUrl, setCurrentImageUrl] = useState<any | null>(null)
  const [dataUri, setDataUri] = useState<string | ''>('')
  const [formErr, setFormErr] = useState<any>({
    logo: {
      error: false,
      message: 'Please select upload logo image',
    },
  })
  const { partner, setPartner, setAllowNext, masterData } = useContext(PartnerContext)
  const history = useHistory()
  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {
      partnerName: partner?.partnerName ? partner?.partnerName : '',
      companyInfo: partner?.companyInfo ? partner?.companyInfo : '',
      websiteLink: partner?.websiteLink ? partner?.websiteLink : '',
      languages: partner?.languages ? partner?.languages : [],
      tier: partner?.tier ? partner?.tier : '',
      country: partner?.country ? partner?.country : '',
      logo: partner?.logo ? partner?.logo : '',
    },
  })

  const validateLogo = () => {
    let isValid = true
    const tempError = { ...formErr }
    if (dataUri?.length == 0) {
      tempError.logo.error = true
      tempError.logo.errorMessage = CreateFormStrings.uploadLogoRequiredError
      isValid = false
    } else {
      tempError.logo.error = false
    }
    setFormErr(tempError)
    return isValid
  }

  const checkAsciiChars = (integratorName: string) => {
    return [...integratorName].some((char: string) => char?.charCodeAt(0) > 127)
  }

  const integratorBlur = () => {
    const currentValue = getValues()
    if (currentValue?.partnerName?.length > 0) {
      const hasNonAscii = checkAsciiChars(currentValue?.partnerName)
      if (hasNonAscii) {
        setError('partnerName', { type: 'custom', message: 'please enter ascii range only' })
      } else {
        clearErrors('partnerName')
      }
    }
  }

  const {
    isCreateErrorVisible,
    setIsCreateErrorVisible,
    createIntegratorErrorMessage,
    setCreateIntegratorErrorMessage,
    ...restProps
  } = props

  useEffect(() => {
    if (partner?.logo) {
      setLogo(partner?.logo)
      setCurrentImageUrl(partner?.logo)
      setDataUri(partner?.logo)
      setAllowNext(true)
    }
  }, [])

  const validateSelectedFile = (logo: any) => {
    const MAX_FILE_SIZE = 200 // 5MB

    if (!logo) {
      setCreateIntegratorErrorMessage('Please choose a file')
      setIsCreateErrorVisible(true)
      return
    }

    const fileExt = logo?.name?.split('.')?.pop()?.toLowerCase()
    if (!ALLOWED_FILE_EXT.includes(fileExt)) {
      setCreateIntegratorErrorMessage(`Please select valid file type, Valid File Types: ${ALLOWED_FILE_EXT.join(', ')}`)
      setIsCreateErrorVisible(true)
      return
    }

    const fileSizeKiloBytes = logo.size / 1024

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setCreateIntegratorErrorMessage('File size is greater than maximum limit')
      setIsCreateErrorVisible(true)
      return
    }

    setCreateIntegratorErrorMessage('')
    setIsCreateErrorVisible(false)
    return true
  }

  const onSubmit = async (data: any) => {
    const isValid = validateLogo()
    if (!isValid) {
      return false
    }
    data.logo = dataUri
    setPartner((preState: any) => {
      return { ...preState, ...data }
    })

    restProps.next(data)
    return true
  }

  const checklogo = () => {
    const isValid = validateLogo()
    if (!isValid) {
      return false
    }
    return true
  }

  const getLanguages = () => {
    return masterData?.language?.map((languagesItem: { value: string; label: string }, index: number) => {
      return (
        <Option key={`${languagesItem?.value}_${index}`} id={languagesItem?.value} value={languagesItem?.value}>
          {languagesItem?.label}
        </Option>
      )
    })
  }

  const getTierList = () => {
    return masterData.tier?.map((tierItem: { value: string; label: string }, index: number) => {
      return (
        <Option key={`${tierItem?.value}_${index}`} id={tierItem?.value} value={tierItem?.value}>
          {tierItem?.label}
        </Option>
      )
    })
  }

  const getCountryList = () => {
    return masterData?.country?.map((item: { value: string; label: string }, index: number) => {
      return (
        <Option key={`${item?.value}_${index}`} id={item?.value} value={item?.value}>
          {item?.label}
        </Option>
      )
    })
  }

  const onUploadLogo = (e: any) => {
    const isValidImage = validateSelectedFile(e?.target?.files[0])
    if (!isValidImage) {
      return false
    }
    setLogo(e?.target?.files[0])
    setCurrentImageUrl(URL.createObjectURL(e?.target?.files[0]))
    fileToDataUri(e?.target?.files[0]).then((dataUri: any) => {
      setDataUri(dataUri)
    })
    return true
  }

  const removeLogo = () => {
    setDataUri('')
    setLogo(null)
    setCurrentImageUrl(null)
  }

  useEffect(() => {
    if (dataUri?.length > 0) {
      validateLogo()
    }
  }, [logo, dataUri])

  const fileToDataUri = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event: any) => {
        resolve(event?.target?.result)
      }
      reader.readAsDataURL(file)
    })

  const onBack = () => {
    history.push('/dashboard/partners')
  }

  const filterOption = (input: any, option: any) => {
    if (option.children) {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false
    } else if (option.label) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false
    }
    return false
  }

  return (
    <>
      <PartnerP>{PartnerStrings.partnerInformation1}</PartnerP>
      <StyledLine />
      <RequiredFieldsText>{`* indicates required fields`}</RequiredFieldsText>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <InnerContainer>
            <UploadFileSection>
              {currentImageUrl === null && (
                <FileUpload label={'Upload File*'} onChange={onUploadLogo} height={'200px'} />
              )}
              {formErr?.logo?.error && <ErrorMessage>{formErr?.logo?.errorMessage}</ErrorMessage>}
              <ImageBtn>
                {currentImageUrl?.length > 0 && <PreviewImage src={currentImageUrl} />}
                <ImageName>{logo?.name}</ImageName>
                {dataUri?.length > 0 && (
                  <UploadBtn type="primary" onClick={removeLogo}>
                    Remove Image
                  </UploadBtn>
                )}
              </ImageBtn>
            </UploadFileSection>
            <Vl />
            <FormSection>
              <div>
                <InputsContainer>
                  <Row gutter={24}>
                    <Col span={12}>
                      <InputColumnUp>
                        <FormLabel htmlFor="partner-name-input-js" id="partner-name-input-label-js">
                          {CreateFormStrings.partnerName}*
                        </FormLabel>
                        <Controller
                          render={({ field }) => (
                            <InputName id="partner-name-input-js" {...field} disabled={false} onBlur={integratorBlur} />
                          )}
                          name="partnerName"
                          control={control}
                          rules={{
                            required: true,
                            maxLength: {
                              value: PARTNER_NAME_MAX_LENGTH,
                              message: CreateFormStrings.partnerNameLengthError,
                            },
                            minLength: {
                              value: PARTNER_NAME_MIN_LENGTH,
                              message: CreateFormStrings.partnerNameLengthError,
                            },
                          }}
                        />
                        {errors.partnerName && (
                          <ErrorMessage>
                            {errors.partnerName.message || CreateFormStrings.partnerNameRequiredError}
                          </ErrorMessage>
                        )}
                      </InputColumnUp>
                    </Col>
                    <Col span={12}>
                      <InputColumnUp>
                        <FormLabel htmlFor="website-link-input-js" id="website-link-input-label-js">
                          {CreateFormStrings.websiteLink}*
                        </FormLabel>
                        <div>
                          <Controller
                            render={({ field }) => <InputName id="website-link-input-js" {...field} disabled={false} />}
                            name="websiteLink"
                            control={control}
                            rules={{
                              required: true,
                              pattern: PARTNER_WEBSITE_LINK_REGX,
                              validate: validateWebsiteLink,
                            }}
                          />
                          {errors.websiteLink && (
                            <ErrorMessage>
                              {errors.websiteLink.message || CreateFormStrings.websiteLinkRequiredError}
                            </ErrorMessage>
                          )}
                        </div>
                      </InputColumnUp>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={12}>
                      <InputColumnRight>
                        <FormLabel>{CreateFormStrings.selectLanguages}*</FormLabel>
                        <Controller
                          render={({ field }) => (
                            <TypeSelector
                              showSearch
                              mode="multiple"
                              id="language-selector-js"
                              {...field}
                              filterOption={filterOption}
                            >
                              {getLanguages()}
                            </TypeSelector>
                          )}
                          name="languages"
                          control={control}
                          rules={{
                            required: true,
                          }}
                        />
                        {errors.languages && (
                          <ErrorMessage>
                            {errors.languages.message || CreateFormStrings.languageRequiredError}
                          </ErrorMessage>
                        )}
                      </InputColumnRight>
                    </Col>
                    <Col span={12}>
                      <InputColumnRight>
                        <FormLabel>{CreateFormStrings.selectByTier}*</FormLabel>
                        <Controller
                          render={({ field }) => (
                            <TypeSelector showSearch id="tier-selector-js" {...field}>
                              {getTierList()}
                            </TypeSelector>
                          )}
                          name="tier"
                          control={control}
                          rules={{
                            required: true,
                          }}
                        />
                        {errors.tier && (
                          <ErrorMessage>{errors.tier.message || CreateFormStrings.tierRequiredError}</ErrorMessage>
                        )}
                      </InputColumnRight>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={12}>
                      <InputColumnRight>
                        <FormLabel>{CreateFormStrings.selectCountry}*</FormLabel>
                        <Controller
                          render={({ field }) => (
                            <TypeSelector showSearch id="country-selector-js" {...field} filterOption={filterOption}>
                              {getCountryList()}
                            </TypeSelector>
                          )}
                          name="country"
                          control={control}
                          rules={{
                            required: true,
                          }}
                        />
                        {errors.country && (
                          <ErrorMessage>
                            {errors.country.message || CreateFormStrings.countryRequiredError}
                          </ErrorMessage>
                        )}
                      </InputColumnRight>
                    </Col>
                  </Row>
                  <Row>
                    <Col></Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <InputColumn>
                        <FormLabel htmlFor="app-desc-input-js">{CreateFormStrings.companyInfo}*</FormLabel>
                        <Controller
                          render={({ field }) => (
                            <InputDescription rows={6} id="company-info-input-js" {...field} disabled={false} />
                          )}
                          name="companyInfo"
                          control={control}
                          rules={{
                            required: true,
                            maxLength: {
                              value: COMPANY_INFO_MAX_LENGTH,
                              message: CreateFormStrings.companyInfoMaxLengthError,
                            },
                            minLength: {
                              value: COMPANY_INFO_MIN_LENGTH,
                              message: CreateFormStrings.companyInfoMinLengthError,
                            },
                          }}
                        />
                        {errors.companyInfo && (
                          <ErrorMessage>
                            {errors.companyInfo.message || CreateFormStrings.companyInfoRequiredError}
                          </ErrorMessage>
                        )}
                      </InputColumn>
                    </Col>
                  </Row>
                </InputsContainer>
              </div>
            </FormSection>
          </InnerContainer>
        </Container>
        <BottomStyleLine />
        <div className="steps-action">
          <FormButton type="primary" onClick={onBack}>
            Cancel
          </FormButton>
          <Button type="primary" htmlType="submit" onClick={validateLogo}>
            NEXT
          </Button>
        </div>
      </form>
    </>
  )
}

export default PartnerInfo
