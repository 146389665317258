import { Checkbox, Select } from 'antd'
import styled from 'styled-components'
import { ReactComponent as CaretDown } from '../../../assets/icon-caret-down.svg'
import { ReactComponent as CaretUp } from '../../../assets/icon-caret-up.svg'
import { ReactComponent as FalafelMenu } from '../../../assets/icons-falafel-menu.svg'
import plusIcon from '../../../assets/plus-icon.svg'
import { Button, H4, H5 } from '../../../shared/components'
import { color } from '../../../shared/utils/styles'
import { RowContent } from '../Styles'
import { ReactComponent as OrangeFlag } from '../../../assets/orange-flag.svg'
import { ReactComponent as YellowFlag } from '../../../assets/yellow-flag.svg'
import { ReactComponent as RedFlag } from '../../../assets/red-flag.svg'

export const PlusIcon = styled.div`
  width: 172px;
  height: 172px;
  background-image: url(${plusIcon});
  background-repeat: no-repeat;
`

export const CreateButton = styled(Button)`
  width: 234px;
`

export const ProfileDetails = styled.div`
  color: ${color.gray10};
`

export const Section = styled.div`
  margin-bottom: 54px;
`

export const UserNameRowContent = styled(RowContent)`
  flex-basis: 20%;
`

export const RowButton = styled(Button)`
  min-width: 88px;
  margin-left: auto;
`

export const ModalBodyContainer = styled.div`
  margin-top: 0.75em;
  margin-bottom: 0.5em;
`

export const ModalBodyTitle = styled(H4)`
  margin-top: 0;
  margin-bottom: 1em;
`

export const ModalBodyText = styled(H5)`
  margin: 0;
`

export const DeleteAccountCheckbox = styled(Checkbox)`
  margin-top: 1.25em;
`

export const CheckboxLabel = styled.label`
  color: ${color.gray10};
`

export const ModalFooterButtons = styled.div`
  display: flex;
  justify-content: space-around;
`

export const ModalButton = styled(Button)`
  padding-left: 4em;
  padding-right: 4em;
`
export const RowExpand = styled.div`
  padding-right:12px;
}
`
export const IconExpand = styled(CaretDown)`
  cursor: pointer;
  vertical-align: middle;
`

export const IconHide = styled(CaretUp)`
  cursor: pointer;
  vertical-align: middle;
`

export const MoreItem = styled(FalafelMenu)`
  cursor: pointer;
  vertical-align: middle;
`
export const MoreDetailsTitle = styled(H5)`
  cursor: pointer;
  color: ${color.lightBlue};
  margin-bottom: 42px;
  margin-top: 9px;
  padding-left: 0px;
`

export const OrganizationDetails = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 4fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;

  grid-template-areas:
    'OrganizationIDHeader CountryHeader RegisteredAPIsHeader ResourcesHeader ResourcesHeader MembersHeader MembersHeader'
    'OrganizationID Country RegisteredAPIs EquipmentCount BuildingsCount MembersCount ApplicationCount';

  max-width: 1388px;
  padding: 8px 8px 8px 16px;

  background-color: ${color.gray70};
  color: ${color.gray10};
`

const HeaderCol = styled.div`
  border-bottom: 1px solid ${color.gray60};
  font-size: 14px;
  padding-right: 16px;
`

export const OrganizationIDHeader = styled(HeaderCol)`
  grid-area: OrganizationIDHeader;
`

export const CountryHeader = styled(HeaderCol)`
  grid-area: CountryHeader;
`

export const RegisteredAPIsHeader = styled(HeaderCol)`
  grid-area: RegisteredAPIsHeader;
`

const CenteredHeaderCol = styled(HeaderCol)`
  text-align: center;
  padding: 0 8px;
`

export const ResourcesHeader = styled(CenteredHeaderCol)`
  grid-area: ResourcesHeader;
`

export const MembersHeader = styled(CenteredHeaderCol)`
  grid-area: MembersHeader;
`

const Value = styled.div`
  padding: 16px 16px 16px 0;
`

export const OrganizationID = styled(Value)`
  grid-area: OrganizationID;
`

export const Country = styled(Value)`
  grid-area: Country;
`

export const RegisteredAPIs = styled(Value)`
  grid-area: RegisteredAPIs;
`

const CountContainer = styled(Value)`
  padding: 16px 8px 16px 8px;
  text-align: center;
`

export const EquipmentCount = styled(CountContainer)`
  grid-area: EquipmentCount;
`

export const BuildingsCount = styled(CountContainer)`
  grid-area: BuildingsCount;
`

export const MembersCount = styled(CountContainer)`
  grid-area: MembersCount;
`

export const ApplicationCount = styled(CountContainer)`
  grid-area: ApplicationCount;
`

export const NumberValue = styled.div`
  font-size: 21.6px;
`

export const NumberValueHeader = styled.span`
  display: inline-block;
`

export const ResourceFlagOrange = styled(OrangeFlag)`
  margin-left: 5px;
`

export const ResourceFlagYellow = styled(YellowFlag)`
  margin-left: 5px;
`
export const ResourceFlagRed = styled(RedFlag)`
  margin-left: 5px;
`

export const TypeSelector = styled(Select)`
  width: 150px;
  margin-bottom: 25px;
  margin-top: 10px;
  overflow-y: 'scroll';
`
