import React, { FC, useEffect, useState } from 'react'
import { CreateFormStrings, PartnerStrings } from '../../../../shared/strings/PartnersContent'
import Modal from '../../../../shared/components/ModalPopUp'
import { Col, Row } from 'antd'
import { Contact } from '../../Models'
import {
  ButtonContainer,
  CancelButton,
  ContactHeader,
  Container,
  FormLabel,
  InputColumn,
  InputName,
  InputsContainer,
  PartnerP,
  RemoveIconContact,
  RequiredFieldsText,
  SaveButton,
  StyledLine,
} from './Styles'
import { ErrorMessage } from '../../../../shared/components'
import { SalesPageAPI } from '../../../../shared/api/salespage'

export interface EditContactInfoProps {
  isEditContactInfoOpen: boolean
  closeEditContactInfo: () => void
  contactInfo?: any
  isCreateErrorVisible: boolean | false
  setIsCreateErrorVisible: (data: boolean) => void
  createIntegratorErrorMessage?: string | ''
  setCreateIntegratorErrorMessage: (msg: string) => void
  invalidateIntegratorData: () => void
  setIsSuccess: (data: boolean) => void
  setSuccessMsg: (data: string) => void
}
const EditContactInfo: FC<EditContactInfoProps> = (props) => {
  const defaultContact: Contact = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    jobTitle: '',
  }
  const [contacts, setContacts] = useState<Contact[] | []>([{ ...defaultContact }])
  // const [isSuccess, setIsSuccess] = useState<boolean | false>(false)
  // const [successMsg, setSuccessMsg] = useState<string | ''>('')
  const defaultError = {
    firstName: {
      error: false,
      message: 'Please enter first name',
    },
    lastName: {
      error: false,
      message: 'Please enter last name',
    },
    email: {
      error: false,
      message: 'Please enter a valid email',
    },
    phoneNumber: {
      error: false,
      message: 'Please enter a valid phone number',
    },
    jobTitle: {
      error: false,
      message: 'Please enter a job title',
    },
  }
  const [formErr, setFormErr] = useState<any>([{ ...defaultError }])
  const {
    isEditContactInfoOpen,
    closeEditContactInfo,
    contactInfo,
    isCreateErrorVisible,
    setIsCreateErrorVisible,
    createIntegratorErrorMessage,
    setCreateIntegratorErrorMessage,
    invalidateIntegratorData,
    setIsSuccess,
    setSuccessMsg,
  } = props

  useEffect(() => {
    console.log('contactInfo:', contactInfo)
    setTimeout(
      () =>
        setContacts([
          {
            email: contactInfo[0]?.email,
            firstName: contactInfo[0]?.firstName,
            jobTitle: contactInfo[0]?.jobTitle,
            lastName: contactInfo[0]?.lastName,
            phoneNumber: contactInfo[0]?.phoneNumber.join(','),
          },
        ]),
      1000
    )
  }, [])

  const onClickHandle = async () => {
    const isValid = validateContact()
    if (!isValid) {
      return false
    }

    const payload = {
      contactInfo: [
        { ...contacts[0], phoneNumber: contacts[0]?.phoneNumber?.split(',')?.map((s: string) => s.trim()) },
      ],
      partnerStep: 3,
      partnerContactId: contactInfo[0]?.partnerContactId,
    }

    const resopnse = await SalesPageAPI.editIntegrator(payload, contactInfo[0]?.partnerId)
    if (!resopnse.ok) {
      const newRes = await resopnse.json()
      setIsCreateErrorVisible(true)
      setCreateIntegratorErrorMessage(newRes?.message)
    } else if (resopnse.status === 202) {
      const newRes = await resopnse.json()
      setSuccessMsg('Intergrator updated successfully.')
      setIsSuccess(true)
      setTimeout(() => {
        closeEditContactInfo()
        invalidateIntegratorData()
      }, 2000)
    }
    return true
  }

  const validateContact = () => {
    const errorFlags: boolean[] = []
    const tempError: any = [...formErr]
    console.log('tempError:', tempError)
    contacts?.map((contact: Contact, index: number) => {
      if (!contact?.firstName || contact?.firstName?.length == 0) {
        tempError[index].firstName.error = true
        errorFlags.push(false)
      } else {
        tempError[index].firstName.error = false
      }

      if (!contact?.lastName || contact?.lastName?.length == 0) {
        tempError[index].lastName.error = true
        errorFlags.push(false)
      } else {
        tempError[index].lastName.error = false
      }

      if (!contact?.email || contact?.email?.length == 0) {
        tempError[index].email.error = true
        errorFlags.push(false)
      } else {
        tempError[index].email.error = false
      }
      const regx = /^([+]?)+\d{8,15}(,[\s\s]?([+]?)+\d{8,15})*$/
      if (!contact?.phoneNumber || contact?.phoneNumber?.length == 0 || !regx.test(contact?.phoneNumber)) {
        tempError[index].phoneNumber.error = true
        errorFlags.push(false)
      } else {
        tempError[index].phoneNumber.error = false
      }

      if (!contact?.jobTitle || contact?.jobTitle?.length == 0) {
        tempError[index].jobTitle.error = true
        errorFlags.push(false)
      } else {
        tempError[index].jobTitle.error = false
      }
    })
    console.log('tempErrortempError:', tempError)
    setFormErr(tempError)
    return !errorFlags.includes(false)
  }

  const checkTouched = (index: number, key: string, value: string) => {
    if (formErr[index]?.[key].error === true && value && value?.length > 0) {
      const newFormErr = [...formErr]
      if (newFormErr[index]?.[key].error) {
        newFormErr[index][key].error = false
        setFormErr(newFormErr)
      }
    }
  }

  const onInputChange = (e: any, index: number, key: string) => {
    const { value } = e.target
    const tempContacts: Contact[] = [...contacts]
    tempContacts[index] = { ...tempContacts[index], [key]: value }
    checkTouched(index, key, value)
    setContacts(tempContacts)
  }

  const onPhoneNumberChange = (e: any, index: number, key: string) => {
    const { value } = e.target
    const tempContacts: Contact[] = [...contacts]
    tempContacts[index] = { ...tempContacts[index], [key]: value }
    setContacts(tempContacts)
    return true
  }

  const removeContact = (index: number) => {
    const tempContacts = [...contacts]
    tempContacts.splice(index, 1)
    setContacts(tempContacts)
  }
  const getContactContent = () => {
    const contactContent = contacts.map((contact: any, index: number) => (
      <InputsContainer key={`contact_tr_${index}`}>
        <PartnerP>{PartnerStrings.contactInformation}</PartnerP>
        <StyledLine />
        <RequiredFieldsText>{PartnerStrings.requireFieldText}</RequiredFieldsText>
        <ContactHeader>
          {/* <Underline>{PartnerStrings.integratorContactDetails}</Underline> */}
          {index > 0 && <RemoveIconContact onClick={() => removeContact(index)} />}
        </ContactHeader>

        <Row gutter={24}>
          <Col span={8}>
            <InputColumn>
              <FormLabel htmlFor="first-name-input-js" id="first-name-input-label-js">
                {CreateFormStrings.firstName}*
              </FormLabel>
              <InputName
                id="first-name-input-js"
                onChange={(e: any) => onInputChange(e, index, 'firstName')}
                disabled={false}
                value={contact?.firstName}
              />
              {formErr?.length > 0 && formErr[index].firstName?.error && (
                <ErrorMessage>{formErr[index].firstName?.message}</ErrorMessage>
              )}
            </InputColumn>
          </Col>
          <Col span={8}>
            <InputColumn>
              <FormLabel htmlFor="last-name-input-js" id="last-name-input-label-js">
                {CreateFormStrings.lastName}*
              </FormLabel>
              <InputName
                id="last-name-input-js"
                onChange={(e: any) => onInputChange(e, index, 'lastName')}
                disabled={false}
                value={contact?.lastName}
              />
              {formErr?.length > 0 && formErr[index].lastName?.error && (
                <ErrorMessage>{formErr[index].lastName?.message}</ErrorMessage>
              )}
            </InputColumn>
          </Col>
          <Col span={8}>
            <InputColumn>
              <FormLabel htmlFor="email-input-js" id="email-input-label-js">
                {CreateFormStrings.email}*
              </FormLabel>
              <InputName
                type="email"
                required
                id="email-input-js"
                onChange={(e: any) => onInputChange(e, index, 'email')}
                disabled={false}
                value={contact?.email}
              />
              {formErr?.length > 0 && formErr[index].email?.error && (
                <ErrorMessage>{formErr[index].email?.message}</ErrorMessage>
              )}
            </InputColumn>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <InputColumn>
              <FormLabel htmlFor="phone-number-input-js" id="phone-number-input-label-js">
                {CreateFormStrings.phoneNumber}*
              </FormLabel>

              <InputName
                id="phone-number-input-js"
                onChange={(e: any) => onPhoneNumberChange(e, index, 'phoneNumber')}
                disabled={false}
                value={contact?.phoneNumber}
              />
              {formErr?.length > 0 && formErr[index].phoneNumber?.error && (
                <ErrorMessage>{formErr[index].phoneNumber?.message}</ErrorMessage>
              )}
            </InputColumn>
          </Col>
          <Col span={8}>
            <InputColumn>
              <FormLabel htmlFor="job-title-input-js" id="job-title-input-label-js">
                {CreateFormStrings.jobTitle}*
              </FormLabel>
              <InputName
                id="job-title-input-js"
                onChange={(e: any) => onInputChange(e, index, 'jobTitle')}
                disabled={false}
                value={contact?.jobTitle}
              />
              {formErr?.length > 0 && formErr[index].jobTitle?.error && (
                <ErrorMessage>{formErr[index].jobTitle?.message}</ErrorMessage>
              )}
            </InputColumn>
          </Col>
        </Row>
        <StyledLine />
      </InputsContainer>
    ))
    return contactContent
  }

  return (
    <>
      <Modal
        key="edit-contact-info"
        isOpen={isEditContactInfoOpen}
        title={PartnerStrings.editContactInfo}
        onClose={closeEditContactInfo}
        width={'900px'}
        height={'300px'}
      >
        <Container>{getContactContent()}</Container>
        <ButtonContainer>
          <CancelButton onClick={closeEditContactInfo}>{PartnerStrings.cancel}</CancelButton>
          <SaveButton onClick={onClickHandle}>{PartnerStrings.saveChanges}</SaveButton>
        </ButtonContainer>
      </Modal>
    </>
  )
}

export default EditContactInfo
