import React, { useEffect, useRef, useState } from 'react'
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  FileIcon,
  MaxFileSizeText,
  PixelText,
  SupportedFileText,
} from './Styles'

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 200000000
const DEFAULT_HEIGHT = '300px'
const DEFAULT_LABEL = 'Upload Files'
const KILO_BYTES_PER_BYTE = 1000
const SUPPORTED_FILE_TYPES = ['png', 'svg', 'jpeg', 'jpg', 'gif']

const convertBytesToKB = (bytes: any) => Math.round(bytes / KILO_BYTES_PER_BYTE)

const FileUpload = ({
  label = DEFAULT_LABEL,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  height = DEFAULT_HEIGHT,
  ...otherProps
}: any) => {
  const fileInputField = useRef(null)
  const [files, setFiles] = useState({})

  useEffect(() => {
    console.log(fileInputField)
  }, [fileInputField])
  return (
    <>
      <FileUploadContainer height={height}>
        {/* <InputLabel>{label}</InputLabel> */}
        <FileIcon />
        <DragDropText>{label}</DragDropText>
        <MaxFileSizeText>
          Max file size:{maxFileSizeInBytes / 1000000}
          {`KB`}
        </MaxFileSizeText>
        <PixelText>{`250 X 150 pixels`}</PixelText>
        <SupportedFileText>
          Supported file type :
          <br />
          {SUPPORTED_FILE_TYPES.join(', ')}
        </SupportedFileText>
        <FormField type="file" ref={fileInputField} title="" value="" {...otherProps} />
      </FileUploadContainer>
    </>
  )
}

export default FileUpload
