import { Col, Row } from 'antd'
import React, { FC } from 'react'
import { H4, P } from '../../../../shared/components/Typography'
import { PayloadProperty } from '../../components/PayloadProperty'
import {
  CodeBlock,
  LeftCol,
  Paragraph,
  RightCol,
  SectionRow,
  StyledEventName,
  HeaderRow,
  ContentRow,
  Column,
} from '../../Styles'
import {
  escalatorMovementDisableEnvs,
  movementStartedDisableEnvs,
  elvatorDoorButtonDisableEnvs,
} from '../../../../shared/constants/common'
import awsConfig from '../../../../shared/utils/awsConfig'
import { EventType } from '../../../../shared/models/events'
import { WebhookStrings, TableContent, ServiceModes } from '../../../../shared/strings/EquipmentContent'

export const WebhookEvents: FC = () => {
  return (
    <SectionRow>
      <LeftCol span="12">
        <H4 id="availability-event">Equipment availability information</H4>
        <HeaderRow>
          <Column>{TableContent.supporteEquipment}</Column>
        </HeaderRow>
        <ContentRow>
          <Column>{TableContent.kone}</Column>
          <Column>{TableContent.elevator}</Column>
          <Column>Escalator</Column>
        </ContentRow>
        <ContentRow>
          <Column>{TableContent.nonKone}</Column>
          <Column>{TableContent.elevator}</Column>
          <Column></Column>
        </ContentRow>
        <Row>
          <Col span={5}>
            <StyledEventName>Availability</StyledEventName>
          </Col>
          <Col span={19}>
            <P size="small">
              This event is sent when the availability of the equipment is changed. It supports KONE elevators,
              escalators and NON-KONE elevators.
            </P>

            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty
              name="id"
              type="string"
              required={true}
              description={`unique subscription id of the event which belongs to particular subscription only`}
            />

            <PayloadProperty
              name="type"
              type="string"
              required={true}
              description={` define the type of event`}
              example={'com.kone.equipment.availability.v1'}
            />

            <PayloadProperty
              name="equipmentId"
              type="string"
              required={true}
              description={`KEN (KONE/ NON-KONE equipment number) for the equipment with ‘ken:’ prefix`}
              example={WebhookStrings.kenExample}
            />

            <PayloadProperty
              name="specversion"
              type="string"
              required={true}
              description={`This is the version of payload schema, this version will change when payload changes.`}
              example={'1.0'}
            />

            <PayloadProperty
              name="time"
              type="string"
              required={true}
              description={`ISO 8601 timestamp of when the availability status last changed`}
            />

            <PayloadProperty
              name="devices"
              type="array of AvailabilityResponse_devices_inner objects"
              description={`Connectivity status of individual devices. Please note that an event with the status "Disconnected" will be triggered 24 hours after the device has actually been disconnected.`}
              required={true}
            />
            <Row style={{ marginLeft: '90px', width: '90%' }}>
              <Col span="22">
                <PayloadProperty
                  name="status"
                  type="string"
                  description={'Connection status'}
                  stringEnums={['Disconnected', 'Connected']}
                  required={true}
                />
              </Col>
            </Row>
            <Row style={{ marginLeft: '90px', width: '90%' }}>
              <Col span="22">
                <PayloadProperty
                  name="device­Type"
                  type="string"
                  description={'Device type'}
                  stringEnums={['anylift', 'kcecpuc', 'dtu']}
                  required={true}
                />
              </Col>
            </Row>

            <PayloadProperty
              name="state"
              type="string"
              required={true}
              description={`Defines the current state of an equipment`}
              stringEnums={['InService', 'Disconnected', 'MaintenanceOngoing', 'Unknown', 'OutOfService']}
              stringEnumDescriptions={[
                {
                  value: 'InService',
                  description: `Equipment is in service.`,
                },
                {
                  value: 'OutOfService',
                  description: `Equipment is out of service.`,
                },
                {
                  value: 'MaintenanceOngoing',
                  description: `Equipment is undergoing maintenance.`,
                },
                {
                  value: 'Disconnected',
                  description: `Equipment has been disconnected from the system. No events can be received.`,
                },
                {
                  value: 'Unknown',
                  description: `The state of equipment is not known. Device has not sent its first state or has reported an invalid state.`,
                },
              ]}
            />

            <PayloadProperty
              name="previous­State"
              type="string"
              required={true}
              description={`Previous operational state of an equipment`}
              stringEnums={['InService', 'Disconnected', 'MaintenanceOngoing', 'Unknown', 'OutOfService']}
              stringEnumDescriptions={[
                {
                  value: 'InService',
                  description: `Equipment is in service.`,
                },
                {
                  value: 'OutOfService',
                  description: `Equipment is out of service.`,
                },
                {
                  value: 'MaintenanceOngoing',
                  description: `Equipment is undergoing maintenance.`,
                },
                {
                  value: 'Disconnected',
                  description: `Equipment has been disconnected from the system. No events can be received.`,
                },
                {
                  value: 'Unknown',
                  description: `The state of equipment is not known. Device has not sent its first state or has reported an invalid state.`,
                },
              ]}
            />
            <PayloadProperty
              name="serviceMode"
              type="string"
              description={`The mode the equipment operates in`}
              stringEnums={ServiceModes.modes}
              stringEnumDescriptions={ServiceModes.modeDescription}
            />

            <PayloadProperty
              name="equipment­Info"
              type="object of type EquipmentInfo"
              description={`Equipment information, for example location and description. This field is an optional field. Some fields may or may not be present  `}
            />
            <Row style={{ marginLeft: '120px', width: '90%' }}>
              <Col span="22">
                <PayloadProperty name="serial­Number" type="string" description={WebhookStrings.serialNumber} />
                <PayloadProperty name="equipmentNumber" type="string" />
                <PayloadProperty
                  name="customerEquipmentNumber"
                  type="string"
                  description={WebhookStrings.equipmentDescription}
                />
                <PayloadProperty
                  name="type"
                  type="string"
                  description={WebhookStrings.equipmentType}
                  stringEnums={WebhookStrings.equipmentTypeExamples}
                />
                <PayloadProperty name="city" type="array" description={WebhookStrings.cityName} />
                <PayloadProperty name="countryCode" type="string" description={WebhookStrings.countryCode} />
                <PayloadProperty name="­street" type="string" description={WebhookStrings.streetName} />
                <PayloadProperty name="postalCode" type="string" description={WebhookStrings.postalCode} />
                <PayloadProperty name="name" type="array" description={WebhookStrings.equipmentDesc} />
                <PayloadProperty name="houseNumber" type="string" description={WebhookStrings.houseNumber} />
                <PayloadProperty name="region" type="string" description={WebhookStrings.region} />
              </Col>
            </Row>
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Event example</Paragraph>

        <CodeBlock language="json">
          {`
 [
  {
    "id": "1ccaa022-9f5d-4007-982e-b8f14de53d6a",
    "type": "com.kone.equipment.availability.v1",
    "equipmentID": "ken:100554477",
    "specversion": "1.0",
    "time": "2021-08-09T01:20:42.531Z",
    "data": {
      "devices": [
        {
          "status": "Disconnected",
          "deviceType": "anylift"
        }
      ],
      "state": "InService",
      "previousState": "MaintenanceOngoing",
      "serviceMode": "OutOfService",
    },
    "equipmentInfo": {
      "serialNumber": "123456789",
      "equipmentNumber": "10520444",
      "customerEquipmentNumber": "E1212",
      "type": "Elevator",
      "name": ["KONE Building"],
      "street": "Keilasatama 3",
      "postalCode": "02150",
      "city": ["ESPOO"],
      "countryCode": "FI",
      "houseNumber": "64",
      "region": "UUSIMAA"
    }
  }
]`}
        </CodeBlock>
      </RightCol>
      <LeftCol span="12">
        <H4 id="service-status-event">Equipment status information</H4>
        <HeaderRow>
          <Column>{TableContent.supporteEquipment}</Column>
        </HeaderRow>
        <ContentRow>
          <Column>{TableContent.kone}</Column>
          <Column>{TableContent.elevator}</Column>
          <Column>Escalator</Column>
        </ContentRow>
        <ContentRow>
          <Column>{TableContent.nonKone}</Column>
          <Column>{TableContent.elevator}</Column>
          <Column>Escalator</Column>
        </ContentRow>
        <Row>
          <Col span={5}>
            <StyledEventName>Status</StyledEventName>
          </Col>
          <Col span={19}>
            <P size="small">
              This event provides current status data of the equipment. It supports both KONE and NON-KONE equipments.
            </P>
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty
              name="entrapment"
              type="boolean"
              description={`indication that someone is inside an elevator after it's operation stopped`}
            />
            {/* <PayloadProperty
              name="equipmentNumber"
              type="string"
              description={`Number that belongs to KONE or NON-KONE which uniquely identifies an equipment`}
              example="10520444"
            /> */}

            <PayloadProperty
              name="equipment­Status"
              type="string"
              description={`represents the current status of an equipment`}
              stringEnums={['Normal Operation', 'Out of order', 'Dummy', 'Dismantle']}
            />

            <PayloadProperty
              name="equipmentFunctionalLocation"
              type="string"
              description={`number allocated to a geographical mapping within which an equipment is located`}
            />

            <PayloadProperty
              name="equipmentFunctionalLocationDescription"
              type="string"
              description={`description of the geographical mapping within which an equipment is located`}
            />

            <PayloadProperty
              name="customerEquipmentLocationId"
              type="string"
              description={`it is an inventory number which is assigned to different sub parts of an equipment for Asset Accounting mostly used for reporting and tracing purpose`}
            />

            <PayloadProperty
              name="serviceOrderNumber"
              type="string"
              description={`Unique identification number provided to a request for servicing an equipment.`}
            />

            <PayloadProperty
              name="id"
              type="string"
              required={true}
              description={`unique subscription id of the event which belongs to particular subscription only`}
            />

            <PayloadProperty
              name="time"
              type="string"
              required={true}
              description={`ISO 8601 timestamp of when the equipment status event was transmitted to subscriber`}
            />

            <PayloadProperty
              name="equipmentId"
              type="string"
              required={true}
              description={`KEN (KONE/ NON-KONE equipment number) for the equipment with ‘ken:’ prefix`}
              example={WebhookStrings.kenExample}
            />

            <PayloadProperty
              name="specversion"
              type="string"
              required={true}
              description={`This is the version of payload schema, this version will change when payload changes.`}
              example={'1.0'}
            />

            <PayloadProperty
              name="type"
              type="string"
              required={true}
              description={` define the type of event`}
              example={'com.kone.equipment.status.v1'}
            />

            {/* Uncomment it once the field available
             "customerEquipmentNumber": "A04363_ASC", (Payload)
              <PayloadProperty
              name="customerEquipment­Number"
              type="string"
              required={true}
              description={`unique id provided by customer as an internal identification of the equipment within their system`}
              example={'A04363_ASC'}
            /> */}

            {/* <PayloadProperty
              name="equipmentFailureReasonCode"
              type="string"
              required={true}
              description={`equipment failure reason code`}
            />

            <PayloadProperty
              name="equipmentFailureReasonDescription"
              type="string"
              required={true}
              description={`description after troubleshooting a failed equipment`}
            /> */}

            {/* <PayloadProperty
              name="equipmentConditionOnArrival"
              type="string"
              required={true}
              description={`specifies the condition of the equipment when the technician arrived at the site`}
            />

            <PayloadProperty
              name="equipmentConditionOnDeparture"
              type="string"
              required={true}
              description={`specifies the condition of the equipment when the technician left the site`}
            /> */}

            {/* <PayloadProperty
              name="technicianAction"
              type="string"
              required={true}
              description={`description of the action being performed by a technician towards repairing a failed equipment`}
            />

            <PayloadProperty
              name="callerName"
              type="string"
              required={true}
              description={`name of the person reporting the failure of an equipment`}
            />

            <PayloadProperty
              name="technicianName"
              type="string"
              required={true}
              description={`name of the technician repairing the failed equipment`}
            />

            <PayloadProperty
              name="technicianWorkStartDate"
              type="string"
              required={true}
              description={`indicates date when the equipment repair started, typically when technician gets to site`}
            />

            <PayloadProperty
              name="technicianWorkEndDate"
              type="string"
              required={true}
              description={`indicates date when the equipment repair completed, typically when technician leaves the site`}
            /> */}

            {/* todo : when the code is available
             <PayloadProperty
              name="codeOfMBM"
              type="object"
              required={true}
              description={`reference code of a set modular based maintenance`}
              example={'materialCode : KM896539H01'}
            /> */}

            <PayloadProperty
              name="equipment­Info"
              type="object of type EquipmentInfo"
              description={`Equipment information, for example location and description. This field is an optional field. Some fields may or may not be present `}
            />
            <Row style={{ marginLeft: '120px', width: '90%' }}>
              <Col span="22">
                <PayloadProperty name="serial­Number" type="string" description={WebhookStrings.serialNumber} />
                <PayloadProperty name="equipmentNumber" type="string" />
                <PayloadProperty
                  name="customerEquipmentNumber"
                  type="string"
                  description={WebhookStrings.equipmentDescription}
                />
                <PayloadProperty
                  name="type"
                  type="string"
                  description={WebhookStrings.equipmentType}
                  stringEnums={WebhookStrings.equipmentTypeExamples}
                />
                <PayloadProperty name="city" type="array" description={WebhookStrings.cityName} />
                <PayloadProperty name="countryCode" type="string" description={WebhookStrings.countryCode} />
                <PayloadProperty name="­street" type="string" description={WebhookStrings.streetName} />
                <PayloadProperty name="postalCode" type="string" description={WebhookStrings.postalCode} />
                <PayloadProperty name="name" type="array" description={WebhookStrings.equipmentDesc} />
                <PayloadProperty name="houseNumber" type="string" description={WebhookStrings.houseNumber} />
                <PayloadProperty name="region" type="string" description={WebhookStrings.region} />
              </Col>
            </Row>
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Event example</Paragraph>
        <CodeBlock language="json">
          {`[
 {
    "data": {
      "entrapment": false,
      "equipmentStatus": "Normal Operation",
      "equipmentFunctionalLocation": "FL00016278",
      "equipmentFunctionalLocationDescription": "Marseille",
      "customerEquipmentLocationId": "BAT 1",
      "serviceOrderNumber": "000801332845",
    },
    "id": "c41c1494-b2d9-4d45-ace0-2301f5fc2793",
    "time": "2022-06-16T15:24:57.446Z",
    "equipmentId": "ken:10520444",
    "specversion": "1.0",
    "type": "com.kone.equipment.status.v1",
    "equipmentInfo": {
        "serialNumber": "123456789",
        "equipmentNumber": "10520444",
        "customerEquipmentNumber": "E1212",
        "type" : "Elevator",
        "name": ["KONE Building"],
        "street": "Keilasatama 3",
        "postalCode": "02150",
        "city": ["ESPOO"],
        "countryCode": "FI",
        "houseNumber": "64",
        "region": "UUSIMAA"
      }
  }
]`}
        </CodeBlock>
        {/*
  Todo: uncomment once all fields are verified
        <CodeBlock language="json">
          {`[
  {
    "id": "59272bc5-61d0-4a84-bff0-bdba56ad89b9",
    "time": "2022-08-08T05:00:45.425Z",
    "equipmentId": "ken:33000001",
    "type": "com.kone.equipment.service.status.v1",
    "data": {
      "entrapment": false,
      "equipmentFailureReasonCode": "00",
      "equipmentFailureReasonDescription": "No fault found",
      "technicianAction": "Checked operation",
      "equipmentConditionOnArrival": "Running - Operational malfunctioning",
      "equipmentConditionOnDeparture": "Equipment running, back to normal operation",
      "callerName": "MR MARCIASINI",
      "technicianName": "S HANUMANTHAPPA",
      "technicianWorkStartDate": "2022-06-30T18:30:00Z",
      "technicianWorkEndDate": "2022-07-30T18:30:00Z",
    },
      "equipmentInfo": {
      "equipmentNumber": "33000001",
      "serialNumber": "123456789",
      "type": "Elevator",
      "city": ["Hyvinkää"],
      "countryCode": "FI",
      "street": "Hyvinkäänkatu 25",
      "postalCode": "05800",
      "name": ["PCBUILD test device markku"],
      "region": "Uusimaa"
    }
  }
]`}
        </CodeBlock> */}
      </RightCol>
      <LeftCol span="12">
        <H4 id="movement-event">Equipment movement information</H4>
        <HeaderRow>
          <Column>{TableContent.supporteEquipment}</Column>
        </HeaderRow>
        <ContentRow>
          <Column>{TableContent.kone}</Column>
          <Column>{TableContent.elevator}</Column>
        </ContentRow>
        <ContentRow>
          <Column>{TableContent.nonKone}</Column>
          <Column>{TableContent.elevator}</Column>
        </ContentRow>
        <Row>
          <Col span={5}>
            <StyledEventName>Movement</StyledEventName>
          </Col>
          <Col span={19}>
            <P size="small">
              This event is delivered whenever an elevator completes its journey from the source to destination. It
              supports both KONE and NON-KONE elevators.
            </P>
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <PayloadProperty
              name="id"
              type="string"
              required={true}
              description={`unique subscription id of the event which belongs to particular subscription only`}
            />

            <PayloadProperty
              name="type"
              type="string"
              required={true}
              description={` define the type of event`}
              example={'com.kone.elevator.movement.completed.v1'}
            />

            <PayloadProperty
              name="equipmentId"
              type="string"
              required={true}
              description={`KEN (KONE/ NON-KONE equipment number) for the equipment with ‘ken:’ prefix`}
              example={WebhookStrings.kenExample}
            />

            <PayloadProperty
              name="specversion"
              type="string"
              required={true}
              description={`This is the version of payload schema, this version will change when payload changes.`}
              example={'1.0'}
            />

            <PayloadProperty
              name="time"
              type="string"
              required={true}
              description={`ISO 8601 timestamp of when the movement status last changed.`}
            />

            <PayloadProperty
              name="movement­Event­Type"
              type="string"
              required={true}
              description={`It indicates whether this event regarding equipment's movement stops.`}
              stringEnums={['MovementCompleted']}
              stringEnumDescriptions={[
                {
                  value: `MovementCompleted`,
                  description: `Equipment completed its movement. All fields can be part of the message.`,
                },
              ]}
            />

            <PayloadProperty
              name="durationSeconds"
              type="number"
              required={true}
              description={`Duration in seconds that the journey took.`}
            />

            <PayloadProperty
              name="distanceMeters"
              type="number"
              required={true}
              description={`Distance in meters that the elevator traveled during the journey.`}
            />

            <PayloadProperty
              name="stopMode"
              type="string"
              required={true}
              description={`The mode the elevator stopped in.`}
              stringEnums={[
                'Normal',
                // Below stopModes should be uncommented once the Mov-Comp v2 is enabled
                // 'ServiceDriveMaintenance',
                // 'Synchronization',
                // 'FireFighter',
                // 'EmergencyPower',
                // 'Attendant',
                // 'Freight',
                // 'Parked',
                // 'Fpo',
                // 'EarthQuake',
                // 'FastRecall',
                // 'LiftClosed',
                // 'EtslFailure',
                // 'NtsFailure',
                // 'Evacuation',
                // 'Invasion',
                // 'MainsFailure',
                // 'DopCs',
                // 'OutOfService',
                // 'PriorityCall',
                // 'ResE',
                // 'H4',
                // 'PrlH*HelMes',
                // 'OssPending',
                'Emergency',
                'Nts',
              ]}
              stringEnumDescriptions={[
                {
                  value: 'Normal',
                  description: 'Elevator in normal mode',
                },
                // Below stopModes should be uncommented once the Mov-Comp v2 is enabled
                // {
                //   value: 'ServiceDriveMaintenance',
                //   description: 'Equipment in service drive mode and operated by a maintenance engineer',
                // },
                // {
                //   value: 'Synchronization',
                //   description: 'Equipment synchronization ongoing',
                // },
                // {
                //   value: 'FireFighter',
                //   description: 'Equipment in fire mode, access only by firefighters',
                // },
                // {
                //   value: 'EmergencyPower',
                //   description: 'Equipment currently using emergency power',
                // },
                // {
                //   value: 'Attendant',
                //   description: 'Equipment is being operated by an attendant inside the car',
                // },
                // {
                //   value: 'Freight',
                //   description: 'Equipment reserved for freight',
                // },
                // {
                //   value: 'Parked',
                //   description: 'Equipment at the auto parking floor',
                // },
                // {
                //   value: 'Fpo',
                //   description: 'Equipment removed from group operation',
                // },
                // {
                //   value: 'EarthQuake',
                //   description: 'Equipment in earthquake mode and accepting no calls',
                // },
                // {
                //   value: 'FastRecall',
                //   description: 'Equipment recalled to a specified floor and accepting no calls',
                // },
                // {
                //   value: 'LiftClosed',
                //   description: 'Equipment non-functional and closed for maintenance',
                // },
                // {
                //   value: 'EtslFailure',
                //   description: 'Equipment emergency terminal speed limiting failed',
                // },
                // {
                //   value: 'NtsFailure',
                //   description: 'Equipment normal terminal stopping failed',
                // },
                // {
                //   value: 'Evacuation',
                //   description: 'Equipment being used only for non-fire evacuation',
                // },
                // {
                //   value: 'Invasion',
                //   description: 'Equipment immobilized on an invasion floor',
                // },
                // {
                //   value: 'MainsFailure',
                //   description: 'Equipment power lost',
                // },
                // {
                //   value: 'DopCs',
                //   description: 'Equipment in test drive and door opening disabled',
                // },
                // {
                //   value: 'OutOfService',
                //   description: 'Equipment is non-functional and was set by a Destination Operating Panel',
                // },
                // {
                //   value: 'PriorityCall',
                //   description: 'Equipment only accepts a priority call',
                // },
                // {
                //   value: 'ResE',
                //   description: 'Equipment running at reduced speed',
                // },
                // {
                //   value: 'H4',
                //   description: 'Equipment floor adjustment H4 mode',
                // },
                // {
                //   value: 'PrlH*HelMes',
                //   description:
                //     'Equipment only accepts a priority call for a defined Hospital emergency activated at the elevator landing',
                // },
                // {
                //   value: 'OssPending',
                //   description: 'Equipment waiting to enter non-functional mode',
                // },
                {
                  value: 'Emergency',
                  description: 'Elevator in Emergency mode',
                },
                {
                  value: 'Nts',
                  description: 'Elevator terminal stop',
                },
              ]}
            />

            <PayloadProperty
              name="mode"
              type="string"
              required={true}
              description={`The mode the elevator operates in.`}
              stringEnums={[
                'Normal',
                'ServiceDriveMaintenance',
                'Synchronization',
                'FireFighter',
                'EmergencyPower',
                'Attendant',
                'Freight',
                'Parked',
                'PriorityCall',
                'Fpo',
                'EarthQuake',
                'FastRecall',
                'LiftClosed',
                'EtslFailure',
                'NtsFailure',
                'Evacuation',
                'Evacuation',
                'Invasion',
                'MainsFailure',
                'DopCs',
                'OutOfService',
                'ResE',
                'H4',
                'PrlH*HelMes',
                'OssPending',
                'TestDrive',
                'Aes',
                'PriorityCallPriorityLanding',
                'OutOfServiceCar',
                'OutOfServiceLdg',
                'OutOfServiceOpt',
                'Gateway',
                'PitInspection',
                'SafeWellAccess',
                'Fid',
                'Rdf',
                'Setup',
                'PriorityCallCarLightFailed',
                'Pendant',
                'NoSetup',
                'Idle',
                'Mrt',
                'Ebd',
                'FastRecall',
                'Wsc',
                'WscMa',
                'PriorityCallVip',
                'CarTest',
                'ScdSetup',
                'BarAdjust',
                'StormOperation',
                'ReducedSpeed',
                'CarCleaningCap',
                'CarCleaningCam',
                'Other',
              ]}
              stringEnumDescriptions={[
                {
                  value: `Normal`,
                  description: `Elevator in normal mode`,
                },
                {
                  value: `ServiceDriveMaintenance`,
                  description: `Elevator in service drive mode and operated by maintenance engineer`,
                },
                {
                  value: `Synchronization`,
                  description: `Elevator synchronization ongoing`,
                },
                {
                  value: `FireFighter`,
                  description: `Elevator in fire mode, access only by fire fighters`,
                },
                {
                  value: `EmergencyPower`,
                  description: `Elevator currently using emergency power`,
                },
                {
                  value: `Attendant`,
                  description: `Elevator is being operated by an attendant inside the car`,
                },
                {
                  value: `Freight`,
                  description: `Elevator reserved for freight`,
                },
                {
                  value: `Parked`,
                  description: `Elevator at auto parking floor`,
                },
                {
                  value: `PriorityCall`,
                  description: `Elevator only accept priority call`,
                },
                {
                  value: `Fpo`,
                  description: `Elevator removed from group operation`,
                },
                {
                  value: `EarthQuake`,
                  description: `Elevator in earthquake mode and accepting no calls`,
                },
                {
                  value: `FastRecall`,
                  description: `Elevator recalled to specified floor and accepting no calls`,
                },
                {
                  value: `LiftClosed`,
                  description: `Elevator non functional and closed for maintenance`,
                },
                {
                  value: `EtslFailure`,
                  description: `Elevator emergency terminal speed limiting failed`,
                },
                {
                  value: `NtsFailure`,
                  description: `Elevator normal terminal stopping failed`,
                },
                {
                  value: `Evacuation`,
                  description: `Elevator being used only for non-fire evacuation`,
                },
                {
                  value: `Evacuation`,
                  description: `Elevator being used only for non-fire evacuation`,
                },
                {
                  value: `Invasion`,
                  description: `Elevator immobilized on an invasion floor`,
                },
                {
                  value: `MainsFailure`,
                  description: `Elevator power lost`,
                },
                {
                  value: `DopCs`,
                  description: `Elevator in test drive and door opening disabled`,
                },
                {
                  value: `OutOfService`,
                  description: `Elevator is non-functional and was set by a Destination Operating Panel`,
                },
                {
                  value: `ResE`,
                  description: `Elevator running at reduced speed`,
                },
                {
                  value: `H4`,
                  description: `Elevator floor adjustment H4 mode`,
                },
                {
                  value: `PrlH*HelMes`,
                  description: `Elevator only accept priority call for defined Hospital emergency activated at elevator landing`,
                },
                {
                  value: `OssPending`,
                  description: `Elevator waiting to enter non functional mode`,
                },
                {
                  value: `TestDrive`,
                  description: `Elevator in test drive mode`,
                },
                {
                  value: `Aes`,
                  description: `Elevator in an automatic serving mode`,
                },
                {
                  value: `PriorityCallPriorityLanding`,
                  description: `Elevator only accept priority call from a Landing Call Station`,
                },
                {
                  value: `OutOfServiceCar`,
                  description: `Elevator is non-functional and was set by a Car Operating Panel`,
                },
                {
                  value: `OutOfServiceLdg`,
                  description: `Elevator is non-functional and was set by a Landing Call Station`,
                },
                {
                  value: `OutOfServiceOpt`,
                  description: `Elevator is non-functional and was set by a Digital Input/Output panel`,
                },
                {
                  value: `Gateway`,
                  description: `Elevator IoT gateway failure`,
                },
                {
                  value: `PitInspection`,
                  description: `Elevator pit undergoing inspection and elevator out of service`,
                },
                {
                  value: `SafeWellAccess`,
                  description: `Elevator top / bottom well undergoing inspection`,
                },
                {
                  value: `Fid`,
                  description: `Elevator is non functional due to detected fire`,
                },
                {
                  value: `Rdf`,
                  description: `Elevator controlled with a recall system and not accepting any calls`,
                },
                {
                  value: `Setup`,
                  description: `Elevator setup ongoing`,
                },
                {
                  value: `PriorityCallCarLightFailed`,
                  description: `Elevator priority call indicator non functional in car`,
                },
                {
                  value: `Pendant`,
                  description: `Elevator in pendant mode for limited testing`,
                },
                {
                  value: `NoSetup`,
                  description: `Elevator has no or incomplete setup`,
                },
                {
                  value: `Idle`,
                  description: `Elevator in idle mode`,
                },
                {
                  value: `Mrt`,
                  description: `Elevator machine room temperature detection activated`,
                },
                {
                  value: `Ebd`,
                  description: `Elevator controlled by emergency battery`,
                },
                {
                  value: `FastRecall`,
                  description: `Elevator recalled to specified floor and accepting no calls`,
                },
                {
                  value: `Wsc`,
                  description: `Elevator non functional due to water detected at the base or top`,
                },
                {
                  value: `WscMa`,
                  description: `Elevator non functional due to water detected at the machine room`,
                },
                {
                  value: `PriorityCallVip`,
                  description: `Elevator only accept priority call for defined VIP`,
                },
                {
                  value: `CarTest`,
                  description: `Elevator car test ongoing`,
                },

                {
                  value: `ScdSetup`,
                  description: `Elevator Static converter setup ongoing`,
                },
                {
                  value: `BarAdjust`,
                  description: `Elevator floor adjustment ongoing`,
                },

                {
                  value: `StormOperation`,
                  description: `Elevator mode changed due to storm`,
                },
                {
                  value: `ReducedSpeed`,
                  description: `Elevator running at reduced speed`,
                },
                {
                  value: `CarCleaningCap`,
                  description: `Elevator switched to cleaning priority and not accepting other calls`,
                },
                {
                  value: `CarCleaningCam`,
                  description: `Elevator switched to cleaning mode and not accepting other calls`,
                },
                {
                  value: `Other`,
                  description: `Elevator in other unspecified state`,
                },
              ]}
            />

            <PayloadProperty
              name="decks"
              type="object of type EquipmentMovementResponse_decks"
              description={`Equipment information, for example location and description`}
              required={true}
            />
            <Row style={{ marginLeft: '90px', width: '90%' }}>
              <Col span="22">
                <PayloadProperty
                  name="deckIndex"
                  type="string"
                  description={'Zero-based index of the deck.'}
                  required={true}
                />
                <PayloadProperty name="startFloor" type="object of type Floor" />
                <Row style={{ marginLeft: '90px', width: '90%' }}>
                  <Col span="22">
                    <PayloadProperty
                      name="floorIndex"
                      type="number"
                      required={true}
                      description={`Zero-based index of the floor. With zero being the bottommost floor.`}
                    />
                    <PayloadProperty
                      name="marking"
                      type="string"
                      description={`Human readable marking of the floor.`}
                    />
                  </Col>
                </Row>
                <PayloadProperty name="stopFloor" type="object of type Floor" />
                <Row style={{ marginLeft: '90px', width: '90%' }}>
                  <Col span="22">
                    <PayloadProperty
                      name="floorIndex"
                      type="number"
                      required={true}
                      description={`Zero-based index of the floor. With zero being the bottommost floor.

`}
                    />
                    <PayloadProperty
                      name="marking"
                      type="string"
                      description={`Human readable marking of the floor.`}
                    />
                  </Col>
                </Row>

                <PayloadProperty
                  name="loadPercentage"
                  type="number"
                  required={true}
                  description={`Percentage of the maximum load during the journey.`}
                />
                <PayloadProperty
                  name="estimatedPersons"
                  type="string"
                  required={true}
                  description={`Estimated number of people in the elevator during the journey.`}
                />
              </Col>
            </Row>

            <PayloadProperty
              name="equipment­Info"
              type="object of type EquipmentInfo"
              description={`Equipment information, for example location and description. This field is an optional field. Some fields may or may not be present `}
            />
            <Row style={{ marginLeft: '120px', width: '90%' }}>
              <Col span="22">
                <PayloadProperty name="serial­Number" type="string" description={WebhookStrings.serialNumber} />
                <PayloadProperty name="equipmentNumber" type="string" />
                <PayloadProperty
                  name="customerEquipmentNumber"
                  type="string"
                  description={WebhookStrings.equipmentDescription}
                />
                <PayloadProperty
                  name="type"
                  type="string"
                  description={WebhookStrings.equipmentType}
                  stringEnums={WebhookStrings.equipmentTypeExamples}
                />
                <PayloadProperty name="city" type="array" description={WebhookStrings.cityName} />
                <PayloadProperty name="countryCode" type="string" description={WebhookStrings.countryCode} />
                <PayloadProperty name="­street" type="string" description={WebhookStrings.streetName} />
                <PayloadProperty name="postalCode" type="string" description={WebhookStrings.postalCode} />
                <PayloadProperty name="name" type="array" description={WebhookStrings.equipmentDesc} />
                <PayloadProperty name="houseNumber" type="string" description={WebhookStrings.houseNumber} />
                <PayloadProperty name="region" type="string" description={WebhookStrings.region} />
              </Col>
            </Row>
          </Col>
        </Row>
      </LeftCol>
      <RightCol span="12">
        <Paragraph>Event example</Paragraph>

        <CodeBlock language="json">
          {`[
  {
    "id": "1ccaa022-9f5d-4007-982e-b8f14de53d6a",
    "type": "com.kone.elevator.movement.completed.v1",
    "equipmentId": "ken:100554477",
    "specversion": "1.0",
    "time": "2022-06-30T12:10:00.000Z",
    "data": {
      "movementEventType": "MovementCompleted",
      "durationSeconds": 6,
      "distanceMeters": 15,
      "stopMode": "Normal",
      "mode": "Normal",
      "decks": [
        {
          "deckIndex": 1,
          "startFloor": {
              "floorIndex": 0,
              "marking": "P1"
          },
          "stopFloor": {
              "floorIndex": 3,
              "marking": "2"
          },
          "loadPercentage": 30,
          "estimatedPersons": 2,
        }
      ]
    },
    "equipmentInfo": {
      "serialNumber": "123456789",
      "equipmentNumber": "10520444",
      "customerEquipmentNumber": "E1212",
      "type": "Elevator",
      "name": ["KONE Building"],
      "street": "Keilasatama 3",
      "postalCode": "02150",
      "city": ["ESPOO"],
      "countryCode": "FI",
      "houseNumber": "64",
      "region": "UUSIMAA"
    }
  }
]`}
        </CodeBlock>
      </RightCol>

      {!movementStartedDisableEnvs.includes(awsConfig?.environment) && (
        <>
          <LeftCol span="12">
            <H4 id="movement-started">Movement started</H4>
            <HeaderRow>
              <Column>{TableContent.supporteEquipment}</Column>
            </HeaderRow>
            <ContentRow>
              <Column>{TableContent.kone}</Column>
              <Column>{TableContent.elevator}</Column>
            </ContentRow>
            <Row>
              <Col span={5}>
                <StyledEventName>Movement</StyledEventName>
              </Col>
              <Col span={19}>
                <P size="small">
                  This event is delivered whenever an elevator starts its journey from the source to destination. It
                  also displays which direction the elevator started moving. Movement Started supports KONE elevators.
                </P>
                <br />
                <br />
              </Col>
            </Row>
            <Row>
              <Col span="24">
                <PayloadProperty
                  name="id"
                  type="string"
                  required={true}
                  description={`unique subscription id of the event which belongs to particular subscription only`}
                />

                <PayloadProperty
                  name="time"
                  type="string"
                  required={true}
                  description={`ISO 8601 timestamp of when the movement started status last changed.`}
                />

                <PayloadProperty
                  name="type"
                  type="string"
                  required={true}
                  description={` define the type of event`}
                  example={'com.kone.elevator.movement.started.v1'}
                />

                <PayloadProperty
                  name="equipmentId"
                  type="string"
                  required={true}
                  description={`KEN (KONE/ NON-KONE equipment number) for the equipment with ‘ken:’ prefix`}
                  example={WebhookStrings.kenExample}
                />

                <PayloadProperty
                  name="specversion"
                  type="string"
                  required={true}
                  description={`This is the version of payload schema, this version will change when payload changes.`}
                  example={'1.0'}
                />
                <PayloadProperty
                  name="mode"
                  type="string"
                  required={true}
                  description={`The mode the elevator operates in.`}
                  stringEnums={ServiceModes.modes}
                  stringEnumDescriptions={ServiceModes.modeDescription}
                />

                <PayloadProperty
                  name="direction"
                  type="string"
                  required={true}
                  description={`It indicates whether the elevator is going up or down.`}
                  stringEnums={['Up', 'Down']}
                />

                <PayloadProperty
                  name="decks"
                  type="object of type EquipmentMovementResponse_decks"
                  description={`Equipment information, for example location and description`}
                  required={true}
                />

                <Row style={{ marginLeft: '90px', width: '90%' }}>
                  <Col span="22">
                    <PayloadProperty
                      name="deckIndex"
                      type="string"
                      description={'Zero-based index of the deck.'}
                      required={true}
                    />
                    <PayloadProperty name="startFloor" type="object of type Floor" />
                    <Row style={{ marginLeft: '90px', width: '90%' }}>
                      <Col span="22">
                        <PayloadProperty
                          name="floorIndex"
                          type="number"
                          required={true}
                          description={`Zero-based index of the floor. With zero being the bottommost floor.`}
                        />
                        <PayloadProperty
                          name="marking"
                          type="string"
                          description={`Human readable marking of the floor.`}
                        />
                      </Col>
                    </Row>

                    <PayloadProperty
                      name="loadPercentage"
                      type="number"
                      required={true}
                      description={`Percentage of the maximum load during the journey.`}
                    />
                  </Col>
                </Row>

                <PayloadProperty
                  name="movement­Event­Type"
                  type="string"
                  required={true}
                  description={`It indicates what is type of the movement done by an elevator.`}
                  stringEnums={['MovementStarted']}
                  stringEnumDescriptions={[
                    {
                      value: `MovementStarted`,
                      description: `Equipment started its movement. All fields can be part of the message.`,
                    },
                  ]}
                />

                <PayloadProperty
                  name="equipment­Info"
                  type="object of type EquipmentInfo"
                  description={`Equipment information, for example location and description. This field is an optional field. Some fields may or may not be present `}
                />
                <Row style={{ marginLeft: '120px', width: '90%' }}>
                  <Col span="22">
                    <PayloadProperty name="serial­Number" type="string" description={WebhookStrings.serialNumber} />
                    <PayloadProperty
                      name="equipmentNumber"
                      type="string"
                      description={WebhookStrings.equipmentNumberDesc}
                    />
                    <PayloadProperty
                      name="customerEquipmentNumber"
                      type="string"
                      description={WebhookStrings.equipmentDescription}
                    />
                    <PayloadProperty
                      name="type"
                      type="string"
                      description={WebhookStrings.equipmentType}
                      stringEnums={WebhookStrings.equipmentTypeExamples}
                    />
                    <PayloadProperty name="city" type="array" description={WebhookStrings.cityName} />
                    <PayloadProperty name="countryCode" type="string" description={WebhookStrings.countryCode} />
                    <PayloadProperty name="­street" type="string" description={WebhookStrings.streetName} />
                    <PayloadProperty name="postalCode" type="string" description={WebhookStrings.postalCode} />
                    <PayloadProperty name="name" type="array" description={WebhookStrings.equipmentDesc} />
                    <PayloadProperty name="houseNumber" type="string" description={WebhookStrings.houseNumber} />
                    <PayloadProperty name="region" type="string" description={WebhookStrings.region} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </LeftCol>
          <RightCol span="12">
            <Paragraph>Event example</Paragraph>

            <CodeBlock language="json">
              {`[
  {
    "id": "a1145afe-4dd0-4e81-bae4-b3d08f0c452e",
    "time": "2023-03-27T09:27:48.378Z",
    "type": "com.kone.elevator.movement.started.v1",
    "equipmentId": "ken:10078815",
    "specversion": "1.0",
    "data": {
      "mode": "Synchronization",
      "direction": 1,
      "decks": [
          {
            "deckIndex": 1,
            "startFloor": {
                "floorIndex": 127,
                "marking": "P1"
              },
            "loadPercentage": 34
          }
      ],
      "movementEventType": "MovementStarted"
  },
    "equipmentInfo": {
        "serialNumber": "313860111",
        "equipmentNumber": "10078815",
        "customerEquipmentNumber": "E1212",
        "type": "Elevator",
        "city": ["HELSINKI"],
        "countryCode": "FI",
        "street": "Old Street",
        "postalCode": "00580",
        "name": ["KIINT OY VANHA TALVITIE 7"],
        "houseNumber": "39",
        "region": "UUSIMAA"
    }
  }
]`}
            </CodeBlock>
          </RightCol>
        </>
      )}

      {!escalatorMovementDisableEnvs.includes(awsConfig?.environment) && (
        <>
          <LeftCol span="12">
            <H4 id="escalator-movement">Escalator movement information</H4>
            <Row>
              <Col span={5}>
                <StyledEventName>Movement</StyledEventName>
              </Col>
              <Col span={19}>
                <P size="small">
                  This event delivers Escalator speed and direction which direction the elevator started moving. It also
                  displays previous direction, previous speed and braking distance.
                </P>
                <br />
                <br />
              </Col>
            </Row>
            <Row>
              <Col span="24">
                <PayloadProperty
                  name="id"
                  type="string"
                  required={true}
                  description={`unique subscription id of the event which belongs to particular subscription only`}
                />

                <PayloadProperty
                  name="time"
                  type="string"
                  required={true}
                  description={`ISO 8601 timestamp of when the escalator movement last changed.`}
                />

                <PayloadProperty
                  name="specversion"
                  type="string"
                  required={true}
                  description={`This is the version of payload schema, this version will change when payload changes.`}
                  example={'1.0'}
                />

                <PayloadProperty
                  name="type"
                  type="string"
                  required={true}
                  description={` define the type of event`}
                  example={EventType.EscalatorMovementChangeV1}
                />

                <PayloadProperty
                  name="equipmentId"
                  type="string"
                  required={true}
                  description={`KEN (KONE equipment number) for the equipment with ‘ken:’ prefix`}
                  example={WebhookStrings.kenExample}
                />

                <PayloadProperty
                  name="statusChangeCode"
                  type="number"
                  required={true}
                  description={`This code have different meaning for different values `}
                  stringEnums={['1', '2', '3', '4', '5', '6']}
                />

                <PayloadProperty
                  name="statusChangeDesc"
                  type="string"
                  required={true}
                  description={`This field contains string value for particular statusChangeCode`}
                  stringEnums={[
                    'Full speed to slow speed',
                    'Full speed to stop',
                    'Slow speed to stop',
                    'Stop to full speed',
                    'Stop to slow speed',
                    'Slow speed to Full speed',
                  ]}
                />

                <PayloadProperty
                  name="previousSpeed"
                  type="string"
                  required={true}
                  description={`This is previous speed of escalator in m/s`}
                  example={'0.52'}
                />

                <PayloadProperty
                  name="currentSpeed"
                  type="string"
                  required={true}
                  description={`This is current speed of escalator in m/s`}
                  example={'0.52'}
                />

                <PayloadProperty
                  name="currentDirectionCode"
                  type="number"
                  required={true}
                  description={`This code have different meaning for different values `}
                  stringEnums={WebhookStrings.directionDesc}
                />

                <PayloadProperty
                  name="currentDirectionDesc"
                  type="string"
                  required={true}
                  description={`This field contains string value for particular currentDirectionCode`}
                  stringEnums={WebhookStrings.directionDesc}
                />

                <PayloadProperty
                  name="previousDirectionCode"
                  type="number"
                  required={true}
                  description={`This code have different meaning for different values `}
                  stringEnums={WebhookStrings.directionDesc}
                />

                <PayloadProperty
                  name="previousDirectionDesc"
                  type="string"
                  required={true}
                  description={`This field contains string value for particular previousDirectionCode`}
                  stringEnums={['0: stopped', '1: up', '2: down']}
                />

                <PayloadProperty
                  name="brakingDistance"
                  type="number"
                  required={true}
                  description={`This is the distance in mm Escalator travels after brake applied`}
                  example={'56'}
                />

                <PayloadProperty
                  name="serviceModeCode"
                  type="number"
                  required={true}
                  description={`This code have different meaning for different values `}
                  // example={
                  //   '1: no operation mode, 2: automatic mode, 3: Inspection mode, 4: continuous mode, 5: two direction mode, 6: energy saving mode, 7: Start mode,
                  //    8: Construct mode, 9: NULL'
                  // }
                  stringEnums={['1', '2', '3', '4', '5', '6']}
                />

                <PayloadProperty
                  name="serviceModeDesc"
                  type="string"
                  required={true}
                  description={` This is a detailed description of the service mode as reflected in the service mode code. Correlating with the service mode code,
                  the combinations are:`}
                  example={
                    '1: no operation mode, 2: automatic mode, 3: Inspection mode, 4: continuous mode, 5: two direction mode, 6: energy saving mode'
                  }
                  stringEnums={[
                    'no operation mode',
                    'automatic mode',
                    'inspection mode',
                    'continuous mode',
                    'two direction mode',
                    'energy saving mode',
                  ]}
                  stringEnumDescriptions={[
                    {
                      value: `no operation mode`,
                      description: `After power on ,no mode is selected due to which escalator can not be start by key switch.`,
                    },
                    {
                      value: `automatic mode`,
                      description: `It remains in running state until a stop command is given or a safety device tripped.`,
                    },
                    {
                      value: `inspection mode`,
                      description: `Escalator is in inspection`,
                    },
                    {
                      value: `continuous mode`,
                      description: `It keeps moving at its nominal speed until a stop command is given or a safety device tripped.`,
                    },
                    {
                      value: `two direction mode`,
                      description: `It allows the escalator to operate in both the upward and downward directions simultaneously depending on the intended flow of traffic.`,
                    },
                    {
                      value: `energy saving mode`,
                      description: `when passenger detected escalator speed up to its nominal speed for running time then it reduced to standby speed until
                      next passenger is detected.`,
                    },
                  ]}
                />
                <PayloadProperty
                  name="timestamp"
                  type="string"
                  required={true}
                  description={`The time when escalator starts moving mentioned in ISO 8601 format`}
                  example={'2023-04-28T00:52:12.000Z'}
                />
                <PayloadProperty
                  name="movement­Event­Type"
                  type="string"
                  required={true}
                  description={`It indicates what is type of the movement done by an elevator.`}
                  stringEnums={['EscalatorMovement']}
                  stringEnumDescriptions={[
                    {
                      value: `EscalatorMovement`,
                      description: `Equipment is moving. All fields can be part of the message.`,
                    },
                  ]}
                />

                <PayloadProperty
                  name="equipment­Info"
                  type="object of type EquipmentInfo"
                  description={`Equipment information, for example location and description. This field is an optional field. Some fields may or may not be present `}
                />
                <Row style={{ marginLeft: '120px', width: '90%' }}>
                  <Col span="22">
                    <PayloadProperty name="serial­Number" type="string" description={WebhookStrings.serialNumber} />
                    <PayloadProperty
                      name="equipmentNumber"
                      type="string"
                      description={WebhookStrings.equipmentNumberDesc}
                    />
                    <PayloadProperty
                      name="type"
                      type="string"
                      description={WebhookStrings.equipmentType}
                      stringEnums={WebhookStrings.equipmentTypeExamples}
                    />
                    <PayloadProperty name="city" type="array" description={WebhookStrings.cityName} />
                    <PayloadProperty name="countryCode" type="string" description={WebhookStrings.countryCode} />
                    <PayloadProperty name="­street" type="string" description={WebhookStrings.streetName} />
                    <PayloadProperty name="postalCode" type="string" description={WebhookStrings.postalCode} />
                    <PayloadProperty name="name" type="array" description={WebhookStrings.equipmentDesc} />
                    <PayloadProperty name="houseNumber" type="string" description={WebhookStrings.houseNumber} />
                    <PayloadProperty name="region" type="string" description={WebhookStrings.region} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </LeftCol>
          <RightCol span="12">
            <Paragraph>Event example</Paragraph>

            <CodeBlock language="json">
              {`[
  {
    "id": "1ccaa022-9f5d-4007-982e-b8f14de53d6a",
    "type": "com.kone.escalator.movement.change.v1",
    "equipmentId": "ken:42560753",
    "time": "2023-06-15T10:13:28.000Z",
    "specversion": "1.0",
    "data": {
      "statusChangeCode": 1,
      "statusChangeDesc": "Full speed to slow speed",
      "movementEventType": "EscalatorMovement",
      "previousSpeed": "0.52",
      "currentSpeed": "0.0",
      "currentDirectionCode": 2,
      "currentDirectionDesc": "Down",
      "previousDirectionCode": 1,
      "previousDirectionDesc": "Up",
      "brakingDistance": 56,
      "serviceModeCode": 1,
      "serviceModeDesc": "no operation mode",
      "timestamp": "2023-06-15T10:13:26.000Z"
    },
    "equipmentInfo":{
      "serialNumber": "313860111",
      "equipmentNumber": "10078815",
      "customerEquipmentNumber": "E1212",
      "type": "Elevator",
      "city": ["HELSINKI"],
      "countryCode": "FI",
      "street": "Old Street",
      "postalCode": "00580",
      "name": ["KIINT OY VANHA TALVITIE 7"],
      "houseNumber": "39",
      "region": "UUSIMAA"
  }
  }
]`}
            </CodeBlock>
          </RightCol>
        </>
      )}
      {!elvatorDoorButtonDisableEnvs.includes(awsConfig?.environment) && (
        <>
          <LeftCol span="12">
            <H4 id="elevator-doorevent">Elevator door event</H4>
            <HeaderRow>
              <Column>{TableContent.supporteEquipment}</Column>
            </HeaderRow>
            <ContentRow>
              <Column>{TableContent.kone}</Column>
              <Column>{TableContent.elevator}</Column>
            </ContentRow>
            <Row>
              <Col span={5}>
                <StyledEventName>Door event</StyledEventName>
              </Col>
              <Col span={19}>
                <P size="small">
                  This event delivers information related to the status of an elevator door. It supports only KONE
                  elevators.
                </P>
                <br />
                <br />
              </Col>
            </Row>
            <Row>
              <Col span="24">
                <PayloadProperty
                  name="id"
                  type="string"
                  required={true}
                  description={`Unique subscription id of the event which belongs to particular subscription only`}
                />

                <PayloadProperty
                  name="time"
                  type="string"
                  required={true}
                  description={`ISO 8601 timestamp of when the door event last changed.`}
                />

                <PayloadProperty
                  name="type"
                  type="string"
                  required={true}
                  description={`Defines the type of event`}
                  example={EventType.ElevatorDoorEventV1}
                />

                <PayloadProperty
                  name="specversion"
                  type="string"
                  required={true}
                  description={`This is the version of payload schema, this version will change when payload changes.`}
                  example={'1.0'}
                />
                <PayloadProperty
                  name="equipmentId"
                  type="string"
                  required={true}
                  description={`KEN (KONE/ NON-KONE equipment number) for the equipment with ‘ken:’ prefix`}
                  example={'ken:44530051'}
                />

                <PayloadProperty
                  name="timestamp"
                  type="string"
                  required={true}
                  description={`ISO 8601 timestamp of when an elevator door opened or closed on site`}
                />

                <PayloadProperty
                  name="doorOpenStartedTime"
                  type="string"
                  required={true}
                  description={`Time at which the door of an elevator opened`}
                />

                <PayloadProperty
                  name="doorClosedTime"
                  type="string"
                  required={true}
                  description={`Time at which the door of an elevator closed`}
                />

                <PayloadProperty
                  name="doorSideCode"
                  type="number"
                  required={true}
                  description={`Code representing the side of an elevator door`}
                  stringEnums={['1', '2']}
                  stringEnumDescriptions={[
                    {
                      value: '1',
                      description: 'A side',
                    },
                    {
                      value: '2',
                      description: 'B side',
                    },
                  ]}
                />

                <PayloadProperty
                  name="doorSideDesc"
                  type="string"
                  required={true}
                  description={`Description of the code representing the side of an elevator door`}
                  example="A"
                />

                <PayloadProperty
                  name="deckIndexDesc"
                  type="string"
                  required={true}
                  description={`Describes whether the index code of the deck is upper or lower`}
                  stringEnums={['Upper Deck', 'Lower Deck']}
                />

                <PayloadProperty
                  name="floor"
                  type="number"
                  required={true}
                  description={`Represents the current floor of an elevator`}
                  example={'0'}
                />

                <PayloadProperty
                  name="floorMarking"
                  type="number"
                  required={true}
                  description={`Represents the floor marking of current floor`}
                  example={'G'}
                />

                <PayloadProperty
                  name="loadBeforeDoorOpen"
                  type="number"
                  description={`Represents the load percentage of an elevator before the doors have opened`}
                />

                <PayloadProperty
                  name="loadAfterDoorClose"
                  type="number"
                  description={`Represents the load percentage of an elevator after the doors are closed`}
                />

                <PayloadProperty
                  name="modeCode"
                  type="number"
                  required={true}
                  description={`Code representing the mode of an elevator`}
                />

                <PayloadProperty
                  name="modeDesc"
                  type="string"
                  required={true}
                  description={`The mode the elevator operates in.`}
                  stringEnums={ServiceModes.modes}
                  stringEnumDescriptions={ServiceModes.modeDescription}
                />

                <PayloadProperty
                  name="openingDuration"
                  type="number"
                  required={true}
                  description={`Time taken by an elevator in seconds to open its closed door`}
                />

                <PayloadProperty
                  name="closingDuration"
                  type="number"
                  required={true}
                  description={`Time taken by an elevator in seconds to close its opened door`}
                />

                <PayloadProperty
                  name="closingModeCode"
                  type="number"
                  required={true}
                  description={`Code representing the closing mode an elevator door`}
                  stringEnums={['0', '1']}
                  stringEnumDescriptions={[
                    {
                      value: '0',
                      description: 'Normal close',
                    },
                    {
                      value: '1',
                      description: 'Nudging close',
                    },
                  ]}
                />

                <PayloadProperty
                  name="closingModeDesc"
                  type="string"
                  required={true}
                  description={`Description of code representing the closing mode an elevator door`}
                  example={'normal close, nudging close'}
                />

                <PayloadProperty
                  name="reopensByPhotoCell"
                  type="number"
                  required={true}
                  description={`Number of times an elevator door reopened due to Photocell Cut`}
                />

                <PayloadProperty
                  name="reopensByDOB"
                  type="number"
                  required={true}
                  description={`Number of times of an elevator door reopened due to Door Open Button`}
                />

                <PayloadProperty
                  name="equipment­Info"
                  type="object of type EquipmentInfo"
                  required={true}
                  description={`Equipment information, for example location and description. This field is an optional field. Some fields may or may not be present `}
                />
                <Row style={{ marginLeft: '120px', width: '90%' }}>
                  <Col span="22">
                    <PayloadProperty name="serial­Number" type="string" description={WebhookStrings.serialNumber} />
                    <PayloadProperty
                      name="equipmentNumber"
                      type="string"
                      description={WebhookStrings.equipmentNumberDesc}
                    />
                    <PayloadProperty
                      name="customerEquipmentNumber"
                      type="string"
                      description={WebhookStrings.equipmentDescription}
                    />
                    <PayloadProperty
                      name="type"
                      type="string"
                      description={WebhookStrings.equipmentType}
                      stringEnums={WebhookStrings.equipmentTypeExamples}
                    />
                    <PayloadProperty name="city" type="array" description={WebhookStrings.cityName} />
                    <PayloadProperty name="countryCode" type="string" description={WebhookStrings.countryCode} />
                    <PayloadProperty name="­street" type="string" description={WebhookStrings.streetName} />
                    <PayloadProperty name="postalCode" type="string" description={WebhookStrings.postalCode} />
                    <PayloadProperty name="name" type="array" description={WebhookStrings.equipmentDesc} />
                    <PayloadProperty name="houseNumber" type="string" description={WebhookStrings.houseNumber} />
                    <PayloadProperty name="region" type="string" description={WebhookStrings.region} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </LeftCol>
          <RightCol span="12">
            <Paragraph>Event example</Paragraph>

            <CodeBlock language="json">
              {`[
    {
        "id": "1ccaa022-9f5d-4007-982e-b8f14de53d6a",
        "time": "2023-06-22T12:19:47.969Z",
        "type": "com.kone.elevator.door.event.v1",
        "equipmentId": "ken:44530053",
        "specversion": "1.0",
        "data": {
          "timestamp": "2023-06-22T12:19:46.000Z",
          "doorOpenStartedTime": "2023-06-22T12:19:40.000Z",
          "doorClosedTime": "2023-06-22T12:19:46.000Z",
          "doorSideCode": 1,
          "doorSideDesc": "A",
          "deckIndexCode": 1,
          "deckIndexDesc": "Lower Deck",
          "floor": 0,
          "floorMarking": "G",
          "loadBeforeDoorOpen": 50,
          "loadAfterDoorClose": 70,
          "modeCode": 0,
          "modeDesc": "normal",
          "openingDuration": 3,
          "closingDuration": 4,
          "closingModeCode": 1,
          "closingModeDesc": "nudging close",
          "reopensByPhotoCell": 0,
          "reopensByDOB": 0
        } ,
        "equipmentInfo": {
          "serialNumber": "123456789",
          "equipmentNumber": "44530053",
          "customerEquipmentNumber": "E1212",
          "type": "Elevator",
          "city": ["HELSINKI"],
          "countryCode": "FI",
          "street": "Old Street",
          "postalCode": "00580",
          "name": ["KIINT OY VANHA TALVITIE 7"],
          "houseNumber": "61",
          "region": "UUSIMAA"
      }
    }
]`}
            </CodeBlock>
          </RightCol>
        </>
      )}
      {!elvatorDoorButtonDisableEnvs.includes(awsConfig?.environment) && (
        <>
          <LeftCol span="12">
            <H4 id="elevator-buttonevent">Elevator button event</H4>
            <HeaderRow>
              <Column>{TableContent.supporteEquipment}</Column>
            </HeaderRow>
            <ContentRow>
              <Column>{TableContent.kone}</Column>
              <Column>{TableContent.elevator}</Column>
            </ContentRow>
            <Row>
              <Col span={5}>
                <StyledEventName>Button event</StyledEventName>
              </Col>
              <Col span={19}>
                <P size="small">
                  This event delivers information about the status of an elevator landing call station button for
                  elevator upward or downward journey requests. It supports only KONE elevators.
                </P>
                <br />
                <br />
              </Col>
            </Row>
            <Row>
              <Col span="24">
                <PayloadProperty
                  name="id"
                  type="string"
                  required={true}
                  description={`Unique subscription id of the event which belongs to particular subscription only`}
                />

                <PayloadProperty
                  name="time"
                  type="string"
                  required={true}
                  description={`ISO 8601 timestamp of when the elevator button status of an elevator last changed`}
                />

                <PayloadProperty
                  name="type"
                  type="string"
                  required={true}
                  description={`Defines the type of an event`}
                  example={EventType.ElevatorButtonEventV1}
                />

                <PayloadProperty
                  name="specversion"
                  type="string"
                  required={true}
                  description={`This is the version of payload schema, this version will change when payload changes.`}
                  example={'1.0'}
                />
                <PayloadProperty
                  name="equipmentId"
                  type="string"
                  required={true}
                  description={`KEN (KONE/ NON-KONE equipment number) for the equipment with ‘ken:’ prefix`}
                  example={'ken:44530051'}
                />

                <PayloadProperty
                  name="timestamp"
                  type="string"
                  required={true}
                  description={`ISO 8601 timestamp of when the elevator button was pressed`}
                />
                <PayloadProperty
                  name="landingCallType"
                  type="string"
                  required={true}
                  description={`Object of Landing Call Type`}
                />
                <Row style={{ marginLeft: '90px', width: '90%' }}>
                  <Col span="22">
                    <PayloadProperty
                      name="floor"
                      type="number"
                      required={true}
                      description={`Represents the current floor number of an elevator`}
                    />
                    <PayloadProperty
                      name="side"
                      type="string"
                      description={`Represents the current side of an elevator`}
                    />
                    <PayloadProperty
                      name="direction"
                      type="string"
                      description={`Represents the current direction in which elevator is moving`}
                    />
                    <PayloadProperty
                      name="type"
                      type="string"
                      description={`Respresents the  functionality of the requested elevator as normal or special state.`}
                      example="normal"
                    />
                  </Col>
                </Row>

                <PayloadProperty
                  name="equipment­Info"
                  type="object of type EquipmentInfo"
                  required={true}
                  description={`Equipment information, for example location and description. This field is an optional field. Some fields may or may not be present `}
                />
                <Row style={{ marginLeft: '120px', width: '90%' }}>
                  <Col span="22">
                    <PayloadProperty name="serial­Number" type="string" description={WebhookStrings.serialNumber} />
                    <PayloadProperty
                      name="equipmentNumber"
                      type="string"
                      description={WebhookStrings.equipmentNumberDesc}
                    />
                    <PayloadProperty
                      name="customerEquipmentNumber"
                      type="string"
                      description={WebhookStrings.equipmentDescription}
                    />
                    <PayloadProperty
                      name="type"
                      type="string"
                      description={WebhookStrings.equipmentType}
                      stringEnums={WebhookStrings.equipmentTypeExamples}
                    />
                    <PayloadProperty name="city" type="array" description={WebhookStrings.cityName} />
                    <PayloadProperty name="countryCode" type="string" description={WebhookStrings.countryCode} />
                    <PayloadProperty name="­street" type="string" description={WebhookStrings.streetName} />
                    <PayloadProperty name="postalCode" type="string" description={WebhookStrings.postalCode} />
                    <PayloadProperty name="name" type="array" description={WebhookStrings.equipmentDesc} />
                    <PayloadProperty name="houseNumber" type="string" description={WebhookStrings.houseNumber} />
                    <PayloadProperty name="region" type="string" description={WebhookStrings.region} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </LeftCol>
          <RightCol span="12">
            <Paragraph>Event example</Paragraph>

            <CodeBlock language="json">
              {`[
  {
    "id": "1ccaa022-9f5d-4007-982e-b8f14de53d6a",
    "time": "2022-06-30T12:10:00.000Z",
    "type": "com.kone.elevator.button.event.v1",
    "equipmentId": "ken:44530053",
    "specversion": "1.0",
    "data": {
      "timestamp": "2023-06-11T21:06:39.000+02:00",
      "landingCallType": {
        "floor": 5,
        "side": "A",
        "direction": "down",
        "type": "normal"
      }
    },
    "equipmentInfo": {
      "serialNumber": "123456789",
      "equipmentNumber": "44530053",
      "customerEquipmentNumber": "E1212",
      "type": "Elevator",
      "city": ["HELSINKI"],
      "countryCode": "FI",
      "street": "Old Street",
      "postalCode": "00580",
      "name": ["KIINT OY VANHA TALVITIE 7"],
      "houseNumber": "61",
      "region": "UUSIMAA"
   }
  }
]`}
            </CodeBlock>
          </RightCol>
        </>
      )}
    </SectionRow>
  )
}
