import { color } from '../../../shared/utils/styles'
import styled from 'styled-components'
import { Button } from '../../../shared/components'
import { ReactComponent as EditIcon } from '../../../assets/edit-pencil.svg'
import { ReactComponent as IconGlobe } from '../../../assets/ico-globe.svg'
import { ReactComponent as IconVideo } from '../../../shared/assets/video-player-icon.svg'
import { ReactComponent as IconDocument } from '../../../assets/img-document-icon.svg'
import ReactQuill from 'react-quill'
import { Divider, Select } from 'antd'

export const Container = styled.div`
  display: flex;
  width: 100%;
  font-size: 16px;
  color: white;
  align-items: baseline;
  justify-content: flex-start;
  flex-direction: column;
`
export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  font-size: 15px;
  color: white;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
`

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
  color: white;
  background-color: ${color.gray85};
  height: auto;
`

export const LeftContainer = styled.div`
  display: flex;
  width: 45%;
  font-size: 16px;
  color: ${color.gray20};
  align-items: baseline;
  justify-content: flex-start;
  flex-direction: column;
`

export const RightContainer = styled.div`
  display: flex;
  width: 54%;
  font-size: 16px;
  color: white;
  align-items: baseline;
  justify-content: flex-start;
  background-color: ${color.gray80};
  border-radius: 12px;
  margin: 15px;
  padding: 15px;
  height: auto;
  flex-direction: column;
  color: ${color.gray20};
`

export const PartnerName = styled.div`
  margin-left: 20px;
  text-transform: capitalize;
`
export const BackButton = styled(Button)`
  width: 10%;
  height: 50%;
`
export const EditHeader = styled.div`
  width: 95%;
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-top: 25px;
`

export const EditHeading = styled.div``
export const EditHeadingIcon = styled(EditIcon)`
  height: 10px;
  margin-right: 6px;
  display: inline-block;
`

export const GlobeIcon = styled(IconGlobe)`
  height: 10px;
`

export const EditContent = styled.div`
  height: 100%;
  font-size: 0.8em;
  width: 93%;
`

export const CompanyHeader = styled.div`
  width: 95%;
  margin-left: 20px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  align-items: flex-end;
`
export const CompanyHeading = styled.div`
  text-align: left;
  display: inline-block;
  width: 77%;
  margin-left: 10px;
  margin-bottom: 4px;
`
export const CompanyHeaderEditIcon = styled(EditIcon)`
  display: inline-block;
  width: 18%;
`
export const CompanyUpper = styled.section`
  width: 95%;
  display: flex;
  justify-content: start;
  margin-left: 20px;
  margin-top: 25px;
  font-size: 0.8em;
  height: auto;
`
export const LogoSection = styled.section`
  width: 21%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 21px;
`

export const CompanyLogo = styled.img`
  width: 88%;
  height: 70%;
  font-size: 0.7em;
`
export const CompanyLink = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
`

export const DescSection = styled.section`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`
export const DescP = styled.p`
  margin-left: 30px;
  overflow-wrap: break-word;
`

export const SolutionCatTier = styled.div``
export const CompanyMiddle = styled.section`
  width: 95%;
  margin-left: 20px;
  margin-top: 5px;
  display: flex;
  padding-bottom: 36px;
`
export const CompanyMiddleItem = styled.div`
  width: 90%;
  margin-left: 10px;
  margin-top: 15px;
  display: flex;
  font-size: 0.8em;

  ul {
    list-style-type: none;
    padding: 0px;
  }
`
export const ContactTitle = styled.span`
  color: ${color.blueBase};
`

export const CompanyTitle = styled.span`
  color: ${color.blueBase};
  margin-left: 30px;
`

export const CompanyLower = styled.section`
  width: 95%;
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-top: 25px;
  flex-direction: column;
`
export const CompanyLowerHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
export const CompanyLowerMain = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 36px;
`
export const CompanyLowerHeaderItem = styled.div``
export const EditDetailLink = styled.div`
  cursor: pointer;
  font-size: 0.8em;
  margin-top: 2px;
  font-weight: 100;
  background-color: transparent;
  color: ${color.blueBase};
  width: 100px;
  height: 30px;
  border: 0px;
`

export const StyledLine = styled.hr`
  border: 0.5px solid ${color.gray70};
  margin-bottom: 0px;
  opacity: 0.4;
  width: 95%;
  margin-top: 0px;
`
export const StyledLineVertical = styled(Divider)`
  border: 0.5px solid ${color.gray70};
  opacity: 0.4;
  width: 2px;
  height: 120px;
  margin-top: 30px;
`

export const DocButton = styled(Button)`
  width: fit-content;
  height: 50%;
  font-size: 0.8em;
  text-aligh: left;
  margin-top: 15px;
`

export const EditButton = styled(Button)`
  font-size: 0.8em;
  margin-top: 2px;
  font-weight: 100;
  background-color: transparent;
  color: ${color.blueBase};
  width: 100px;
  height: 30px;
  border: 0px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
  &:active {
    background-color: transparent;
  }

  .ant-btn {
    background: transparent;

    &:focus,
    &:active,
    &:hover {
      background: transparent !important;
    }
  }
`

export const Editor = styled(ReactQuill)`
  border: 1px solid ${color.gray20};
  height: auto;
  .ql-container {
    background-color: white;
    color: black;
    overflow: visible;
    overflow-y: auto;
    max-height: 170px;
  }
  .ql-formats {
    color: white;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  padding: 30px 40px 0px 0px;
  flex-direction: row-reverse;
`

export const SaveButton = styled(Button)`
  font-size: 1em;
  height: 32px;
  width: 122px;
  margin-top: 2px;
  font-weight: 100;
  background-color: ${color.blueBase};
  color: ${color.white};
  border: 0px;
  cursor: pointer;

  &hover: {
    background-color: ${color.blueBase};
    color: ${color.white};
  }
`

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 27%;
`
export const TypeSelector = styled(Select)`
  width: 250px;
  //margin-bottom: 35px;
  overflow-y: 'scroll';
`

export const FormLabel = styled.label`
  margin-bottom: 3px;
  margin-top: 10px;
  display: block;
  text-aligh: left;
`
export const LabelContainer = styled.div``
export const CategoryTierContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const CategoryTierContainerItem = styled.div``

export const CommonTitle = styled.p`
  color: ${color.blueBase};
  margin-left: 30px;
`

export const CommonTitleItem = styled.div`
  color: white;
  margin-left: 30px;
`

export const SolutionContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const SolutionLower = styled.div`
  padding: 36px;
`
export const SolutionHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`
export const SolutionHeaderTitle = styled.div`
  font-size: 0.9em;
  color: ${color.blueBase};
`

export const SolutionContent = styled.div`
  font-size: 0.8em;

  h1 {
    color: white;
  }

  h2 {
    color: white;
  }

  h3 {
    color: white;
  }

  overflow-wrap: break-word;
`

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContactHeader = styled.div`
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: flex-end;
  margin: auto;
`
export const ContactContainerHeader = styled.div`
  display: flex;
  width: 95%;
  justify-content: space-around;
  align-items: flex-end;
  margin: auto;
`

export const SupportLangHeader = styled.div`
  width: 100%;
  margin-left: 22px;
`

export const RegionContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const RegionContent = styled.div`
  height: 100%;
  font-size: 0.8em;
  width: 93%;
`
export const CountryListOL = styled.ol`
  padding: 30px 30px;

  li {
    text-transform: capitalize;
    list-style-type: none;
  }
`

export const SalesSupportContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const SalesSupportListOUL = styled.ul`
  padding: 30px 30px;
  font-size: 0.8em;

  li {
    text-transform: capitalize;
    list-style-type: none;
  }
`

export const SolutionCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const LinkContainer = styled.div`
  margin-top: -50px;
  display: flex;
  flex-direction: column;
`

export const LinkHeading = styled.div`
  text-align: left;
  display: inline-block;
  width: 77%;
  margin-left: 5px;
  margin-bottom: 4px;
`

export const LinkBodyTitle = styled.div`
  font-size: 0.9em;
  margin-left: 13px;
  color: ${color.blueBase};
`

export const VideoIcon = styled(IconVideo)`
  margin-top: 5px;
  margin-left: -7px;
`

export const DocumentIcon = styled(IconDocument)`
  margin-top: 5px;
  margin-left: -7px;
`
