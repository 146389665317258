import { Col, Row } from 'antd'
import React, { FC, useContext } from 'react'
import { ErrorMessage } from '../../../../shared/components'
import { CreateFormStrings } from '../../../../shared/strings/PartnersContent'
import { Solution } from '../../Models'
import {
  HeaderSolutionContainer,
  InputColumnRight,
  RemoveIconSolution,
  SolutionContainerItem,
  StyledLine,
  FormLabel,
  InputColumn,
  InputName,
  Editor,
  EditorColumn,
  InputColumnSecond,
  TypeSelector,
} from './Styles'
import { PartnerContext } from '../../AddNewParnter/state'

const { Option } = TypeSelector

const OneSolution: FC<{
  index: number
  solution: Solution
  onInputChange: (e: any, index: number, name: string) => void
  onSelectChange: (e: any, index: number, name: string) => void
  removeSolution: (index: number, solutionId: string | null) => void
  formErr?: any
}> = (props) => {
  const { index: currentSolutionIndex, solution, onInputChange, onSelectChange, formErr, removeSolution } = props

  const { masterData } = useContext(PartnerContext)

  const getAPIs = () => {
    return masterData?.api?.map((apiItem: { value: string; label: string }, index: number) => {
      return (
        <Option key={`${apiItem?.value}_${index}`} id={apiItem?.value} value={apiItem?.value}>
          {apiItem?.label}
        </Option>
      )
    })
  }

  const getSegments = () => {
    return masterData?.segments?.map((item: { value: string; label: string }, index: number) => {
      return (
        <Option key={`${item?.value}_${index}`} id={item?.value} value={item?.value}>
          {item?.label}
        </Option>
      )
    })
  }

  const getCountryList = () => {
    return masterData?.country?.map((item: { value: string; label: string }, index: number) => {
      return (
        <Option key={`${item?.value}_${index}`} id={item?.value} value={item?.value}>
          {item?.label}
        </Option>
      )
    })
  }

  const getCategoryList = () => {
    return masterData?.category?.map((item: { value: string; label: string }, index: number) => {
      return (
        <Option key={`${item?.value}_${index}`} id={item?.value} value={item?.value}>
          {item?.label}
        </Option>
      )
    })
  }

  const filterOption = (input: any, option: any) => {
    if (option.children) {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false
    } else if (option.label) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false
    }
    return false
  }

  // The purpose of the checkSelectedAPIs function is to filter out any selected APIs that are not included
  // in the masterData.api array. It takes an array of selected APIs as input and returns a new array that only contains
  // the selected APIs that are present in the masterData.api array.

  const checkAPIsExistInDb = (selectedAPIs: Array<any> = []) => {
    return selectedAPIs.filter((api) => (masterData?.api).map((item: { value: string }) => item.value).includes(api))
  }

  const checkCategoryExistInDb = (selectedCategory: Array<any> = []) => {
    return selectedCategory.filter((category) =>
      (masterData?.category).map((item: { value: string }) => item.value).includes(category)
    )
  }

  const checkSegmentsExistInDb = (selectedSegments: Array<any> = []) => {
    return selectedSegments.filter((segments) =>
      (masterData?.segments).map((item: { value: string }) => item.value).includes(segments)
    )
  }

  const checkCountryExistInDb = (selectedCountry: Array<any> = []) => {
    return selectedCountry.filter((country) =>
      (masterData?.country).map((item: { value: string }) => item.value).includes(country)
    )
  }

  return (
    <>
      {solution && (
        <SolutionContainerItem>
          <HeaderSolutionContainer>
            <RemoveIconSolution
              onClick={() => removeSolution(currentSolutionIndex, solution?.solutionId ? solution?.solutionId : null)}
            />
          </HeaderSolutionContainer>
          <Row gutter={24}>
            <Col span={6}>
              <FormLabel>{CreateFormStrings.solutionName}*</FormLabel>
              <InputName
                id={`solutionName${currentSolutionIndex}`}
                value={solution?.solutionName}
                size={'small'}
                onChange={(e: any) => onInputChange(e, currentSolutionIndex, 'solutionName')}
              />
              {formErr?.length > 0 && formErr[currentSolutionIndex]?.solutionName?.error && (
                <ErrorMessage>{formErr[currentSolutionIndex]?.solutionName?.message}</ErrorMessage>
              )}
            </Col>
            <Col span={6}>
              <InputColumn>
                <FormLabel>{CreateFormStrings.selectApi}*</FormLabel>
                <TypeSelector
                  showSearch
                  mode="multiple"
                  id="select-apis-selector-js"
                  size={'small'}
                  onChange={(e: any) => onSelectChange(e, currentSolutionIndex, 'apis')}
                  disabled={false}
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  value={checkAPIsExistInDb(solution?.apis)}
                >
                  {getAPIs()}
                </TypeSelector>
                {formErr?.length > 0 && formErr[currentSolutionIndex]?.apis?.error && (
                  <ErrorMessage>{formErr[currentSolutionIndex]?.apis?.message}</ErrorMessage>
                )}
              </InputColumn>
            </Col>
            <Col span={6}>
              <InputColumnRight>
                <FormLabel>{CreateFormStrings.keyCustomerSegments}*</FormLabel>
                <TypeSelector
                  showSearch
                  mode="multiple"
                  size={'small'}
                  id="key_segments-selector-js"
                  onChange={(e: any) => onSelectChange(e, currentSolutionIndex, 'keyCustomerSegments')}
                  disabled={false}
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  value={checkSegmentsExistInDb(solution?.keyCustomerSegments)}
                >
                  {getSegments()}
                </TypeSelector>
                {formErr?.length > 0 && formErr[currentSolutionIndex]?.keyCustomerSegments?.error && (
                  <ErrorMessage>{formErr[currentSolutionIndex]?.keyCustomerSegments?.message}</ErrorMessage>
                )}
              </InputColumnRight>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <InputColumnSecond>
                <FormLabel>{CreateFormStrings.selectCountry}*</FormLabel>
                <TypeSelector
                  showSearch
                  mode="multiple"
                  size={'small'}
                  id="country-selector-js"
                  onChange={(e: any) => onSelectChange(e, currentSolutionIndex, 'country')}
                  disabled={false}
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  value={checkCountryExistInDb(solution?.country)}
                >
                  {getCountryList()}
                </TypeSelector>
                {formErr?.length > 0 && formErr[currentSolutionIndex]?.country?.error && (
                  <ErrorMessage>{formErr[currentSolutionIndex]?.country?.message}</ErrorMessage>
                )}
              </InputColumnSecond>
            </Col>
            <Col span={6}>
              <InputColumnSecond>
                <FormLabel>{CreateFormStrings.selectCategory}*</FormLabel>
                <TypeSelector
                  showSearch
                  mode="multiple"
                  size={'small'}
                  id="category-selector-js"
                  onChange={(e: any) => onSelectChange(e, currentSolutionIndex, 'category')}
                  disabled={false}
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  value={checkCategoryExistInDb(solution?.category)}
                >
                  {getCategoryList()}
                </TypeSelector>
                {formErr?.length > 0 && formErr[currentSolutionIndex]?.category?.error && (
                  <ErrorMessage>{formErr[currentSolutionIndex]?.category?.message}</ErrorMessage>
                )}
              </InputColumnSecond>
            </Col>
            <Col span={8}></Col>
          </Row>
          <Row gutter={24}>
            <Col span={22}>
              <EditorColumn>
                <FormLabel htmlFor="app-desc-input-js">{CreateFormStrings.solutionHighlight}*</FormLabel>
                <Editor
                  theme="snow"
                  value={solution?.solutionHighlights}
                  onChange={(e: any) => onSelectChange(e, currentSolutionIndex, 'solutionHighlights')}
                />
                {formErr?.length > 0 && formErr[currentSolutionIndex]?.solutionHighlights?.error && (
                  <ErrorMessage>{formErr[currentSolutionIndex]?.solutionHighlights?.message}</ErrorMessage>
                )}
              </EditorColumn>
            </Col>
          </Row>
        </SolutionContainerItem>
      )}
      <StyledLine />
    </>
  )
}

export default OneSolution
